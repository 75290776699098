import React, { useEffect, useState } from 'react'
import "./Passbook.css"
import { toast, ToastContainer } from 'react-toastify'
import LoadingSpinner from '../../../components/loadingSpinner/loadingSpinner'
import blackBanner from "../../../../assets/rewards-black-banner.png";
import rewardsLogo from "../../../../assets/rewards-logo.png";
import { Constants } from '../../../utils/Constants';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useHistory } from 'react-router-dom';
import ApiService from '../../../utils/ApiService';
import InfiniteScroll from 'react-infinite-scroll-component';
import { ClipLoader } from 'react-spinners';

const Passbook = ({ merchantId }) => {

    const [loader, setLoader] = useState(false);
    const history = useHistory();
    const { toastStyle, requestTimedOutError } = Constants;
    const userNumber = localStorage.getItem("User");
    const [passbookData, setPassbookData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [moreData, setMoreData] = useState(true);

    useEffect(() => {
        getLoyaltyTransactionsForUser();
    }, [])

    const getLoyaltyTransactionsForUser = async () => {  // for setU payment, need to check for payment status for success page redirection
        const token = localStorage.getItem('sessionId');
        const data = {
            limit: 15,
            offset: offset,
            merchantId: merchantId,
        }
        try {
            if (offset === 0) {
                setLoader(true);
            }
            const result = await ApiService.getLoyaltyTransactionsForUser(data, token);
            if (result?.code === requestTimedOutError) {
                toast(result.message, { autoClose: 500 });
            } else if (result?.data?.code === 0) {
                if (passbookData?.length !== 0) {
                    setPassbookData([...passbookData, ...result?.data?.data]);
                } else {
                    setPassbookData(result?.data?.data);
                }
                if (result?.data?.data?.length === 0 || result?.data?.data?.length < 15) {
                    setMoreData(false);
                }
                window.scrollBy({
                    top: -10, // Negative value to scroll up
                    left: 0,
                    behavior: 'smooth' // Optional: adds a smooth scrolling effect
                });
                setOffset(offset + 15);
            } else {
                toast(result.data.message, { autoClose: 500 });
                setMoreData(false);
            }
        } catch (error) {
            alert(error.message)
        } finally {
            setLoader(false);
        }
    }

    const formatDate = (dateString) => {
        if (dateString) {
            const date = new Date(dateString);
            if (isNaN(date.getTime())) {
                return 'Invalid date';
            }
            const day = new Intl.DateTimeFormat('en-GB', { day: '2-digit' }).format(date);
            const month = new Intl.DateTimeFormat('en-GB', { month: 'short' }).format(date);
            const year = new Intl.DateTimeFormat('en-GB', { year: '2-digit' }).format(date);
            return `${day} ${month} \`${year}`;
        } else {
            return 'Invalid date';
        }
    };

    return (
        <div className="mobile-page-container-style">
            <ToastContainer className="toast-container" hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
            {loader && (
                <LoadingSpinner />
            )}
            <div className='black-banner-container'>
                <img src={blackBanner} alt='bg' />
                <div className='black-banner-content-container'>
                    <div className='rewards-user-container'>
                        <FontAwesomeIcon className='back-icon-rewards' onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards` : '/rewards')} icon={faChevronLeft} />
                        {userNumber && (
                            <p className='user-number-text'>{userNumber?.substring(0, 3)}-xxxx-{userNumber?.slice(-3)}</p>
                        )}
                    </div>
                    <img src={rewardsLogo} className='rewards-logo' alt='logo' />
                </div>
            </div>
            <div className='passbook-container'>
                <p className='passbook-header'>Points History</p>
                <InfiniteScroll
                    dataLength={passbookData.length} //This is important field to render the next data
                    next={getLoyaltyTransactionsForUser}
                    hasMore={moreData}
                    loader={<div className="loader-container">
                        <ClipLoader size={30} color="#125d8d" loading={true} />
                    </div>}
                // endMessage={
                //     <p className="no-more-data-text">
                //         No more data!
                //     </p>
                // }
                >
                    <table className="rewards-passbook-table">
                        <thead>
                            <tr className='table-header-row'>
                                <th className="passbook-field-headers" colSpan="1">Date</th>
                                <th className="passbook-field-headers" colSpan="1">Order Id</th>
                                <th className="passbook-field-headers" colSpan="1">Source</th>
                                <th className="passbook-field-headers" colSpan="1">Points</th>
                            </tr>
                        </thead>
                        <tbody>
                            {passbookData?.length !== 0 && passbookData.map((data, index) => (
                                <tr key={index} className="passbook-data-row">
                                    <td className="passbook-data-cell">
                                        {formatDate(data?.created_at)}
                                    </td>
                                    <td className="passbook-data-cell">
                                        {data?.order_id}
                                    </td>
                                    <td className="passbook-data-cell">
                                        {data?.order_type == 'DEVICE' ? 'In store' : data?.order_type}
                                    </td>
                                    <td className={`passbook-data-cell ${data?.transaction_type == 'EARN' || data?.transaction_type == 'REFUND' ? "green-text" : "red-text"}`}>
                                        {(data?.transaction_type == 'EARN' || data?.transaction_type == 'REFUND' ? '+ ' : '- ') + data?.loyalty_points}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {passbookData?.length === 0 && (
                        <p className="no-data-available-text">No data available</p>
                    )}
                </InfiniteScroll>
            </div>
        </div>
    )
}

export default Passbook