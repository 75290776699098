import React, { useEffect } from 'react'
import './MerchantContactUs.css'
import call_btn from '../../../assets/call_btn.png';
import gmap from '../../../assets/gmap.png';
import location_marker from '../../../assets/location.png';
import timings from '../../../assets/Timings.png';
import calendar from '../../../assets/Calendar.png';
import instagramIcon from '../../../assets/contact-insta.png';
import twitterIcon from '../../../assets/contact-twitter.png';
import fssaiIcon from '../../../assets/Fssai-Logo.png';

import { Divider } from '@mui/material'

const MerchantContactUs = ({ merchantDetails }) => {
  const allEmptyStrings = merchantDetails?.mobile_numbers_enable_on_website?.every((element) => {
    // Check if element is not null or undefined
    if (element != null) {
      // Trim the element and check if it's an empty string
      return element.trim() === '';
    } else if (element === null) {
      // If element is null or undefined, consider it as an empty string
      return true
    } else {
      return false
    }
  });
  const firstNonEmptyIndex = merchantDetails.mobile_numbers_enable_on_website.findIndex((element) => element?.trim() !== '');
  console.log("VVVV", allEmptyStrings, "ind", firstNonEmptyIndex)

  useEffect(() => {
    localStorage.setItem("fromContact", 1)
  }, [])

  const handleMapClick = () => {
    if (merchantDetails.displayAddress.latitude !== "" && merchantDetails.displayAddress.longitude !== "") {
      window.open(`https://www.google.com/maps?q=${merchantDetails.displayAddress.latitude},${merchantDetails.displayAddress.longitude}`, "_blank")
    } else {
      window.open(`https://www.google.com/maps?q=${merchantDetails.latitude},${merchantDetails.longitude}`, "_blank")
    }
  }

  return (
    <div style={{ padding: '0 10px' }}>
      <h6 className='contact-header'>Contact Us</h6>
      <div className='contact-address-container'>
        <div className='address-makr-container'>
          <img src={location_marker} className='contact-data-icon' alt="marker" />
          {merchantDetails.displayAddress.addressline1 !== "" ? (
            <p className="text_inside_businessdetail">{merchantDetails.displayAddress.addressline1}, {merchantDetails.displayAddress.addressline2}, {merchantDetails.displayAddress.city} - {merchantDetails.displayAddress.pincode}</p>
          ) : (
            <p className="text_inside_businessdetail">{merchantDetails.addressline1}, {merchantDetails.addressline2}, {merchantDetails.city} - {merchantDetails.pincode}</p>
          )}

        </div>
        <img src={gmap} className='gmap-icon' alt="map" onClick={handleMapClick} />

      </div>
      <Divider className="dividers" />
      <div className='contact-data-container'>
        <img src={timings} className='contact-data-icon' alt="marker" />
        <div>
          {merchantDetails.opentime === "Open 24 hours" ? (
            <p className="text_inside_businessdetail">Timings: Open 24 hours</p>
          ) : (
            <>
              <p className="text_inside_businessdetail">Timings: {merchantDetails.opentime} - {merchantDetails.closetime} {merchantDetails.closetime2 !== undefined && merchantDetails.closetime2 !== null && merchantDetails.closetime2 !== '' && merchantDetails.opentime2 !== undefined && merchantDetails.opentime2 !== null && merchantDetails.opentime2 !== '' ? `, ${merchantDetails.opentime2} - ${merchantDetails.closetime2}` : ''}</p>
            </>
          )}
        </div>

      </div>
      <Divider className="dividers" />
      <div className='contact-data-container'>
        <img src={calendar} className='contact-data-icon' alt="marker" />
        <p className="text_inside_businessdetail">Open {
          merchantDetails.weeklyoff === '0' ? 'Monday to Saturday' :
            merchantDetails.weeklyoff === '1' ? 'Tuesday to Sunday' :
              merchantDetails.weeklyoff === '2' ? 'Wednesday to Monday' :
                merchantDetails.weeklyoff === '3' ? 'Thursday to Tuesday' :
                  merchantDetails.weeklyoff === '4' ? 'Friday to Wednesday' :
                    merchantDetails.weeklyoff === '5' ? 'Saturday to Thursday' :
                      merchantDetails.weeklyoff === '6' ? 'Sunday to Friday' :
                        merchantDetails.weeklyoff === '7' ? 'Sunday to Saturday' : ''
        }</p>
        <p className="text_inside_businessdetail closed-day-text">
          {
            merchantDetails.weeklyoff === '0' ? ', closed on Sunday' :
              merchantDetails.weeklyoff === '1' ? ', closed on Monday' :
                merchantDetails.weeklyoff === '2' ? ', closed on Tuesday' :
                  merchantDetails.weeklyoff === '3' ? ', closed on Wednesday' :
                    merchantDetails.weeklyoff === '4' ? ', closed on Thursday' :
                      merchantDetails.weeklyoff === '5' ? ', closed on Friday' :
                        merchantDetails.weeklyoff === '6' ? ', closed on Saturday' : ''
          }
        </p>
      </div>
      <Divider className="dividers" />
      {allEmptyStrings !== true && (
        <div className='contact-data-container'>
          <img src={call_btn} className='contact-data-icon' alt="marker" />
          <div className='phone-numbers-container'>
            {merchantDetails.mobile_numbers_enable_on_website.length !== 0 && (
              merchantDetails.mobile_numbers_enable_on_website.map((data, index) => {
                return (
                  data ? (
                    <a className='ph-number-link' href={`tel:+91${data}`}>
                      <p className="text_inside_businessdetail phone-number-text">{firstNonEmptyIndex === index ? data : `, ${data}`}</p>
                    </a>
                  ) : ''
                )
              })
            )}
          </div>
        </div>
      )}
      {merchantDetails.merchant_email && merchantDetails.merchant_email.showOnWebsite === true && (
        <>
          <Divider className="dividers" />
          <div className='contact-data-container'>
            <p className='text_inside_businessdetail'>{merchantDetails.merchant_email.email}</p>
          </div>
        </>
      )}
      <Divider className="dividers" />
      {merchantDetails.fssaiNo && (
        <>
          <div className='fssai-container'>
            <img src={fssaiIcon} className='fssai-icon' alt='NA' />
            <p className='fssai-lic-no'>Lic. No. {merchantDetails.fssaiNo}</p>
          </div>
          <Divider className="dividers" />
        </>

      )}
      <div className='social-icons-contact-us contact-data-container'>
        {merchantDetails.socialMedia && merchantDetails.socialMedia.instagramUrl !== "" && (
          <a href={`https://www.instagram.com/${merchantDetails.socialMedia.instagramUrl}/`}>
            <img src={instagramIcon} className='contact-social-icon' alt='social' />
          </a>
        )}
        {merchantDetails.socialMedia && merchantDetails.socialMedia.twitterUrl !== "" && (
          <a href={`https://twitter.com/${merchantDetails.socialMedia.twitterUrl}/`}>
            <img src={twitterIcon} className='contact-social-icon' alt='social' />
          </a>
        )}
        {/* <img src={fbContact} className='contact-social-icon' alt='social' />
                  <img src={youtubeContact} className='contact-social-icon' alt='social' /> */}
      </div>
    </div>
  )
}

export default MerchantContactUs;
