import short from 'short-uuid';

const translator = short("0123456789abcdefghijklmnopqrstuvwxyz");

const commonFunctions = {

    currency: (num) => {
        if (num === undefined || num === null) {
            return num;
        }
        const number = Number(num);
        const hasFractionalPart = number % 1 !== 0;
        const fractionDigits = hasFractionalPart ? 1 : 0;
        const curr = new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
            minimumFractionDigits: fractionDigits,
            maximumFractionDigits: fractionDigits
        })
            .format(number)
            .replace(/(\.|,)00$/g, "");
        return curr;
    },

    calcOffer: (discountedPrice, price) => {
        let offer = 100 - (discountedPrice / price) * 100;
        offer = Math.round(offer)
        return offer;
    },

    getShortId: async (uuid) => {
        return translator.fromUUID(uuid)
    },

    getLongId: (uuid) => {
        return translator.toUUID(uuid)
    },

    disallowMinusDecimal: (event) => {
        const key = event.key;
        console.log("Key", event)
        // Prevent '-' (minus) key press if the input value is empty or the caret position is not at the start
        if (key === "-" && (event.target.value === "" || event.target.selectionStart !== 0)) {
            event.preventDefault();
        }

        if (key === "." || key === "Decimal" || key === "Unidentified") {
            console.log("Unidentified")
            event.preventDefault();
        }

        if (event.keyCode === 229 || event.which === 190) {
            console.log("keycode 229")
            event.preventDefault();
          }

        if (key === "0" && event.target.value === "") {
            event.preventDefault();
        }
    }

}

export { commonFunctions };