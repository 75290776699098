import { GoogleMap, useLoadScript, Marker, OverlayView, Polyline, DirectionsRenderer } from '@react-google-maps/api';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import "./OrderSummary.css"
import { APIConfig } from '../../utils/apiConfig';
import ApiService from '../../utils/ApiService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faChevronLeft, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import riderIcon from '../../../assets/cb_rider.png';
import callIcon from '../../../assets/call_fill_white.png';
import callIconBlack from '../../../assets/black-call-btn.png';
import close_btn from '../../../assets/close_btn.png';
import flowerCheck from '../../../assets/flower_check_fill.png';
import call_btn from '../../../assets/call_btn.png';
import whatsappIcon from '../../../assets/homepage-whatsapp-black.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAuthSession } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils'
import { pubsub } from '../../utils/PubSub';
import { setState } from '../../redux/reduxStore';
import { DialogTitle, Divider } from '@mui/material';
import LoadingSpinner from '../../components/loadingSpinner/loadingSpinner';
import { useHistory } from 'react-router-dom';
import { Constants } from '../../utils/Constants';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useStyles from '../../components/Card/styles';
const libraries = ["places"];

fetchAuthSession().then((info) => {
  const cognitoIdentityId = info.identityId;
});

const OrderSummary = React.memo(() => {

  const summaryData = useSelector((state) => state.counter.summaryData);
  // const riderLatLng = useSelector((state) => state.counter.riderLatLng);
  const [isLoading, setIsLoading] = useState(false);
  const subscriptionRef = useRef(null);
  const childId = null;
  const dispatch = useDispatch();
  const classes = useStyles();
  const [modifiedModal, setModifiedModal] = useState(false);
  const merchantdetails = { data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails };

  const merchId = merchantdetails && merchantdetails.data.merchantid;
  const history = useHistory();
  const [helpNumber, setHelpNumber] = useState('');
  const [showNumber, setShowNumber] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [whatsappOpen, setWhatsappOpen] = useState(false);
  var mainMobileVisible = true;
  const indexToSwap = merchantdetails.data.mobile_numbers_enable_on_website.indexOf(merchantdetails.data.mainmobilenumber);

  console.log('Hub merchantdetails:', JSON.stringify(merchantdetails))
  console.log('Hub indexToSwap:', indexToSwap)

  // Check if the valueToCheck is in the array
  if (indexToSwap !== -1) {
    // Make a copy of the array to avoid directly mutating the state
    const newArray = [...merchantdetails.data.mobile_numbers_enable_on_website];

    // Swap the first element with the element containing the valueToCheck
    const temp = newArray[0];
    newArray[0] = newArray[indexToSwap];
    newArray[indexToSwap] = temp;
    merchantdetails.data.mobile_numbers_enable_on_website = newArray

    // Update the state with the new array
  } else {
    mainMobileVisible = false
  }

  useEffect(() => {
    const hubSubscription = Hub.listen('datastore', (data) => {
      const { payload } = data
      console.log('Hub Payload:', payload)
    })
    const subscribeToTopic = async () => {
      if (summaryData.data.order_id || childId) {
        // console.log('Subscribe======>', orderId, childId)
        subscriptionRef.current = pubsub.subscribe({ topics: childId || summaryData.data.order_id }).subscribe({
          next: data => {
            console.log('Message received', data)
            // setRiderLatLng(data.msg)
            dispatch(setState({ key: "riderLatLng", value: data.msg }));
            localStorage.setItem('riderLatLng', JSON.stringify(data.msg));
            // mapRender = true
          },
          error: error => console.log(error),
          complete: () => console.log('Done')
        })
      }
    }
    // Initial subscription
    subscribeToTopic()
    return () => {
      // Unsubscribe when the component unmounts or when no longer needed
      hubSubscription()
      if (subscriptionRef.current) {
        subscriptionRef.current.unsubscribe()
      }
    }
  }, [])

  useEffect(() => {
    getSummaryData();
  }, [])

  const getSummaryData = async () => {
    // setIsLoading(true);
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchId,
    }
    await ApiService.getChannelDetailsv3(data, token)
      .then(async result => {
        if (result?.data?.code == 0) {
          // setIsLoading(false);
          if ((result.data.data.shipment_status < 5)) {
            dispatch(setState({ key: "summaryData", value: { data: result.data.data, destinationLatlng: result.data.destinationLatlng, originLatlng: result.data.originLatlng } }));
            localStorage.setItem('summaryData', JSON.stringify(result.data))
            setApiCalled(true);
          }
        } else {
          console.log(result?.data?.message)
        }
      }
      )
  }

  const currency = (num) => {
    const hasFractionalPart = num % 1 !== 0;
    const fractionDigits = hasFractionalPart ? 1 : 0;
    const curr = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
      .format(num)
      .replace(/(\.|,)00$/g, "")
      .replace("₹", "₹ ");
    return curr;

  };



  function convertToTime(dateString) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${ampm}`;
  }

  const getHelpDeskNumber = async () => {
    const token = localStorage.getItem('sessionId');
    await ApiService.getHelpDeskNumber(token).then(async result => {
      if (result.data.Status === 'Success') {
        console.log("RESpo", result)
        setHelpNumber(result.data.userapp_help_desk)
        setShowNumber(true)
      } else {
        console.log(result?.message)
      }
    })
  }

  const handleWhatsapp = (phoneNumber) => {
    window.open(
      `https://api.whatsapp.com/send?phone=${phoneNumber}&text=Enquiry from customer`
    );
  };

  function hasSingleNonEmptyValue(arr) {
    const nonEmptyValues = arr.filter(value => value !== '');
    return nonEmptyValues.length === 1;
  }

  return (
    <div className='whole-map-container'>
      {isLoading && (
        <LoadingSpinner />
      )}
      <div className='order-sumary-container'>
        <div className='top-bar-summary'>

          <div className='back-shopname-container'>
            <FontAwesomeIcon className='back-btn-summary' icon={faChevronLeft} onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/')} />
            <div>
              <p className='summary-shop-name'>{summaryData.data.shopname}</p>
              <p className='summary-order-info'>Order: {summaryData.data.order_id} | {convertToTime(summaryData.data.order_created_date)}</p>
            </div>
          </div>
          <div className='top-bar-second-part'>
            <div className='top-bar-divider' />
            <div className='business-call-container'>
              <p className='summary-order-info'>Contact Business</p>
              <div className='chat-call-container'>
                <img onClick={() => setWhatsappOpen(true)} className='summary-chat-icon' src={whatsappIcon} alt='NA' />
                <a href={`tel:+91${summaryData.data.mainmobilenumber}`}>
                  <img src={callIconBlack} className='summary-merchant-call-icon' alt='NA' />
                </a>
              </div>

            </div>
          </div>

        </div>
        {summaryData.data.order_amt_to_be_refund > 0 && (
          <div onClick={() => setModifiedModal(true)} className='modified-band'>
            <div className='modified-text-container'>
              <p className='modified-band-text'>Order Modified</p>
              <p className='modified-band-text'>View details & refund status</p>
            </div>
            <button className='view-details-btn'>View Details</button>
          </div>
        )}
        {(summaryData.data.shipment_status && summaryData.data.shipment_status > 0 && summaryData.data.shipment_status < 5) ? (
          <div className='map-data-container'>
            <div className='map_container'>
              <CustomMap />
            </div>
          </div>
        ) : ''}
        <div>

          <div className={`shadow-card ${(summaryData.data.shipment_status && summaryData.data.shipment_status > 0 &&
            summaryData.data.shipment_status < 5) ? 'summary-status-container-alt' : 'summary-status-container'}`}>
            {apiCalled && (
              <StatusComponent />
            )}
          </div>

          <div title='Items' className='summary-items-container'>
            {summaryData.data.order_summary_bf_modify.map((data, index) => (
              <div className={`summary-items-view ${index === 0 ? '' : 'item-border-top'}`} key={index}>

                <div className='summary-image-name-container'>
                  {(data.image_url && data.image_url.length !== 0) ? (
                    <img src={data.image_url[0]} className='summary-item-image' alt='NA' />
                  ) : (
                    <div className='summary-item-image' />
                  )}
                  <p className='summary-normal-font'>{data.item_name}</p>
                </div>
                <div className='count-whole-container'>
                  <div className='summary-count-container'>
                    <p className='summary-count-text'>x {data.count}</p>
                  </div>
                </div>
                {data.discounted_rate ? (
                  <div className='summary-price-container'>
                    <p className='cut-off-rate'>{currency(data.rate)}</p>
                    <p className='summary-rate'>{currency(data.discounted_rate)}</p>
                  </div>
                ) : (
                  <div className='summary-price-container'>
                    <p className='summary-rate'>{currency(data.rate)}</p>
                  </div>
                )}
              </div>
            ))}
          </div>

          {summaryData?.data?.loyalty_points ? (
            <div className='shadow-card summary-earnings-container'>
              <p className="rewards-rupee-icon">₹</p>
              <p className='cart-earnings-text'>You are earning {summaryData?.data?.loyalty_points} points on this order</p>
            </div>
          ) : ''}

          <div className='summary-items-container bill-container'>
            {summaryData.data.PaymentDone == 0 && (
              <div>
                <p className='prepaid-order-text'>Prepaid Order. No payment to delivery partner</p>
              </div>
            )}

            <div className='summary-bill-data-container'>
              <p className='bill-header'>Bill Details</p>
              <p className='bill-header'>{summaryData.data.order_summary.length} {summaryData.data.order_summary.length === 1 ? 'Item' : 'Items'}</p>
            </div>
            <div className='summary-bill-data-container'>
              <p className='bill-data-text'>MRP</p>
              <p className='bill-data-text-black'>{currency(summaryData.data.full_amount_bf_modify)}</p>
            </div>
            {Number(summaryData.data.full_amount_bf_modify) - Number(summaryData.data.order_total_amount_bf_modify) > 0 ? (
              <div className='summary-bill-data-container'>
                <p className='bill-data-text'>Discount</p>
                <p className='bill-data-text-green'>{'- ' + currency(Number(summaryData.data.full_amount_bf_modify) - Number(summaryData.data.order_total_amount_bf_modify))}</p>
              </div>
            ) : ''}
            <div className='summary-bill-data-container'>
              <p className='bill-data-text'>Item total</p>
              <p className='bill-data-text-black'>{currency(summaryData.data.order_total_amount_bf_modify)}</p>
            </div>
            <div className='cart_divider' />
            {(summaryData.data.total_user_logistic_charge_bf_modify && summaryData.data.total_user_logistic_charge_bf_modify !== 'null') && (
              <div className='summary-bill-data-container'>
                <p className='bill-data-text'>Delivery Charge</p>
                <p className='bill-data-text-black'>{currency(summaryData.data.total_user_logistic_charge_bf_modify)}</p>
              </div>
            )}
            {(summaryData.data.govt_gst_bf_modify && summaryData.data.govt_gst_bf_modify !== 'null') && (
              <>
                <div className='summary-bill-data-container'>
                  <p className='bill-data-text'>Taxes</p>
                  <p className='bill-data-text-black'>{currency(summaryData.data.govt_gst_bf_modify)}</p>
                </div>
                <div className='cart_divider' />
              </>

            )}

            {(summaryData.data.user_offer_amt && summaryData.data.user_offer_amt != 0) ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="bill_amount_bold" style={{ color: '#2bb673', fontWeight: '300', marginBottom: 0, marginTop: 0 }}>{summaryData.data?.offerText}</p>
                  <p className='bill_amount_bold' style={{ color: '#2bb673' }}>{'- ' + currency(summaryData.data.user_offer_amt)}</p>
                </div>
                <div className='cart_divider' /></>
            ) : ''}
            {(summaryData.data.loyalty_points_used && summaryData.data.loyalty_points_used != 0) ? (
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className="bill_amount_bold" style={{ color: '#2bb673', fontWeight: '300', marginBottom: 0, marginTop: 0 }}>Points Redeemed</p>
                  <p className='bill_amount_bold' style={{ color: '#2bb673' }}>{'- ' + currency(summaryData.data.loyalty_points_used)}</p>
                </div>
                <div className='cart_divider' /></>
            ) : ''}
            <div className='summary-bill-data-container'>
              <p className='bill-data-text-paid'>Paid</p>
              <p className='bill-data-text-paid'>{currency(summaryData.data.amt_to_pay)}</p>
            </div>
            {summaryData.data.refundAmount && summaryData.data.refundAmount !== '0' ? (
              <>
                <div className='cart_divider' />
                <div className='summary-bill-data-container'>
                  <p className='bill_amount_bold green-text'>Refunded</p>
                  <p className='bill_amount_bold green-text'>{(summaryData?.data?.refunded_points && summaryData?.data?.refunded_points != '0') ? `${currency(summaryData.data.refundAmount)} + ${summaryData.data.refunded_points} Points` : currency(summaryData.data.refundAmount)}</p>
                </div>
              </>
            ) : ''}

          </div>

        </div>
        <div onClick={getHelpDeskNumber} className='help-div'>Help ?</div>
        <Dialog classes={{ paper: classes.botm_dialog }} open={modifiedModal} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0", maxHeight: '50%' } }} onClose={() => setModifiedModal(!modifiedModal)}>
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() => {
              setModifiedModal(!modifiedModal);
            }}
            alt="close"
          />
          <p className="order-modified-header">
            Order Modified
          </p>
          {summaryData.data.orderModifiedMsg && (
            <p className="order-modified-msg">{summaryData.data.orderModifiedMsg}</p>
          )}

          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
            <div title='Items' className='summary-items-container'>
              {summaryData.data.order_summary.map((data, index) => (
                <div className={`summary-items-view ${index === 0 ? '' : 'item-border-top'}`} key={index}>
                  <div className='summary-image-name-container'>
                    <img src={data.image_url[0]} className='summary-item-image' alt='NA' />
                    <div>
                      <p className='summary-normal-font'>{data.item_name}</p>
                      <p className='req-qty-text'>Req QTY: {data.originalCount}</p>
                    </div>
                  </div>
                  <div className='count-whole-container'>
                    <div className='summary-count-container'>
                      <p className={data.count < data.originalCount ? 'modified-count-text' : 'summary-count-text'}>x {data.count}</p>
                    </div>
                  </div>
                  {data.discounted_rate ? (
                    <div className='summary-price-container'>
                      <p className='cut-off-rate'>{currency(data.rate)}</p>
                      <p className='summary-rate'>{currency(data.discounted_rate)}</p>
                    </div>
                  ) : (
                    <div className='summary-price-container'>
                      <p className='summary-rate'>{currency(data.rate)}</p>
                    </div>
                  )}

                </div>
              ))}
            </div>
          </DialogContent>
          <DialogTitle>
            {summaryData.data.refundMsg && (
              <div className='refunded-text-container'>
                <p className='refunded-msg'>{summaryData.data.refundMsg}</p>
                <img src={flowerCheck} className='flower-check' alt='NA' />
              </div>
            )}
          </DialogTitle>
        </Dialog>

        <Dialog classes={{ paper: classes.botm_dialog }} open={showNumber} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setShowNumber(!showNumber)}>
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() => {
              setShowNumber(!showNumber);
            }}
            alt="close"
          />
          <p className="call-contact-us-header">
            Contact
          </p>
          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
            <div className="call-contact-container">
              <div className="phone-owner-container">
                <div style={{ width: '180px' }}>
                  <a style={{ color: 'black', display: 'flex', alignItems: 'center' }} href={`tel:+91${helpNumber}`}>
                    <img src={call_btn} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                    <p className="phone_call_text">{helpNumber}</p>
                  </a>
                </div>
              </div>
              <Divider className="phone-number-divider" />
            </div>
          </DialogContent>
        </Dialog>

        {/* Whatsapp numbers layover */}
        <Dialog classes={{ paper: classes.botm_dialog }} open={whatsappOpen} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setWhatsappOpen(false)}>
          <img
            src={close_btn}
            className="call_close_btn"
            onClick={() =>
              setWhatsappOpen(false)
            }
            alt="close"
          />
          <p className="call-contact-us-header">
            Whatsapp
          </p>
          <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
            <div className="call-contact-container">
              {merchantdetails.data.mobile_numbers_enable_on_website && merchantdetails.data.mobile_numbers_enable_on_website.length !== 0 && (
                merchantdetails.data.mobile_numbers_enable_on_website.map((data, index) => {
                  return (
                    data ? (
                      <>
                        {console.log("MERRRR,", merchantdetails.data.mobile_numbers_enable_on_website)}
                        <div className="phone-owner-container">
                          <div style={{ width: '180px' }}>
                            <div onClick={() => handleWhatsapp(data)} style={{ color: 'black', display: 'flex', alignItems: 'center' }}>
                              <img src={call_btn} style={{ height: '20px', width: '20px', marginRight: '10px' }} alt='call' />
                              <p className="phone_call_text">{data}</p>
                            </div>

                          </div>
                          {mainMobileVisible ? (
                            <p className="business-owner-text">{index === 0 ? 'Business Owner' : index === 1 ? 'Staff 1' : 'Staff 2'}</p>
                          ) : (
                            <p className="business-owner-text">{index === 0 ? 'Staff 1' : index === 1 ? 'Staff 2' : ''}</p>
                          )}
                        </div>
                        <div hidden={hasSingleNonEmptyValue(merchantdetails.data.mobile_numbers_enable_on_website)}>
                          <Divider className="phone-number-divider" />
                        </div>
                      </>

                    ) : ''
                  )
                })
              )}
            </div>
          </DialogContent>
        </Dialog>

      </div>
    </div>
  );
})

export default OrderSummary;

// MAP Component

const CustomMap = React.memo(() => {
  const mapRef = useRef(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: APIConfig.gmapApiKey,
    libraries,
  });

  const mapOptions = {
    mapTypeControl: false,
    zoomControl: false,
    streetViewControl: false,
    fullscreenControl: false,
    myLocationButton: true,
    clickableIcons: false,
    disableDefaultUI: true,
    gestureHandling: 'greedy',
    scaleControl: false,
    keyboardShortcuts: false,
  };
  // const riderLatLng = useSelector((state) => state.counter.riderLatLng);
  const summaryData = useSelector((state) => state.counter.summaryData);
  const centerLat = (parseFloat(summaryData.originLatlng.latitude) + parseFloat(summaryData.destinationLatlng.latitude)) / 2;
  const centerLng = (parseFloat(summaryData.originLatlng.longitude) + parseFloat(summaryData.destinationLatlng.longitude)) / 2;

  if (loadError) return <div>Map cannot be loaded right now, sorry.</div>;
  if (!isLoaded) return <div>Loading...</div>;

  const getPixelPositionOffset = (width, height) => {
    const xOffset = -(width / 2);
    return {
      x: xOffset,
      y: -42,
    };
  }

  const pathCoordinates = [
    { lat: parseFloat(summaryData.originLatlng.latitude), lng: parseFloat(summaryData.originLatlng.longitude) },
    { lat: parseFloat(summaryData.destinationLatlng.latitude), lng: parseFloat(summaryData.destinationLatlng.longitude) }
  ];

  return (
    <GoogleMap
      mapContainerStyle={Constants.mapContainerStyle}
      zoom={13}
      center={{ lat: centerLat, lng: centerLng }}
      options={mapOptions}
      ref={mapRef}
    >
      {console.log("MAP RERENDERED")}

      {/* Merchant marker */}
      <OverlayView
        position={{ lat: parseFloat(summaryData.originLatlng.latitude), lng: parseFloat(summaryData.originLatlng.longitude) }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
        zIndex={66}
      >
        <div className='map-marker-container'>
          <FontAwesomeIcon className='user-marker' icon={faMapMarkerAlt} />
          <p className='marker-label'>{summaryData.data.shopname}</p>
        </div>
      </OverlayView>

      {/* Rider marker */}
      <RiderMarker />

      {/* User marker */}
      <OverlayView
        position={{ lat: parseFloat(summaryData.destinationLatlng.latitude), lng: parseFloat(summaryData.destinationLatlng.longitude) }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={getPixelPositionOffset}
        zIndex={66}
      >
        <div className='map-marker-container'>
          <FontAwesomeIcon className='merchant-marker' icon={faMapMarkerAlt} />
          <p className='marker-label'>{summaryData.data.label}</p>
        </div>
      </OverlayView>

      <Polyline
        path={pathCoordinates}
        options={{
          strokeColor: "#FF0000",
          strokeOpacity: 1.0,
          strokeWeight: 0,
          zIndex: 10,
          icons: [
            {
              icon: {
                path: window.google.maps.SymbolPath.CIRCLE,
                fillColor: "black",
                fillOpacity: 1,
                scale: 3,
                strokeWeight: 0,
              },
              offset: "5px",
              repeat: "20px",
            },
          ],
        }}
      />
    </GoogleMap>
  )
})

const RiderMarker = React.memo(() => {
  const riderLatLng = useSelector((state) => state.counter.riderLatLng);

  const getPixelPositionOffset = () => {
    return {
      x: -15,
      y: -42,
    };
  }

  return (
    <>
      {riderLatLng?.lat && (
        <OverlayView
          position={{ lat: parseFloat(riderLatLng.lat), lng: parseFloat(riderLatLng.lng) }}
          mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
          getPixelPositionOffset={getPixelPositionOffset}
          zIndex={99}
        >
          <img src={riderIcon} className='rider-marker' alt='rider' />
        </OverlayView>
      )}
    </>


  )
})

const StatusComponent = () => {
  const [duration, setDuration] = useState('');
  const dispatch = useDispatch();
  const merchantdetails = { data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails };
  const merchId = merchantdetails && merchantdetails.data.merchantid;
  console.log('merchantdetails : ', JSON.stringify(merchantdetails))
  const [statusData, setStatusData] = useState('');
  const [refunded, setRefunded] = useState(false);
  const [intervalId, setInervalId] = useState(null);

  useEffect(() => {
    const summaryData = JSON.parse(localStorage.getItem('summaryData'))
    console.log("summaryDatasummaryData :", summaryData)

    if (summaryData !== null) {
      let dat = {
        orderMsg: summaryData.data.orderMsg,
        riderName: summaryData.data.riderName,
        shipment_status: summaryData.data.shipment_status,
        riderMobNo: summaryData.data.riderMobNo,
        destinationLatlng: summaryData.destinationLatlng,
        rider_speed: summaryData.data.rider_speed,

      }
      setStatusData(dat)
    }

  }, [])

  const calculateETA = async () => {

    const riderLatLng = JSON.parse(localStorage.getItem('riderLatLng'))
    if (riderLatLng?.lat) {
      getETA()
    }
  }

  useEffect(() => {
    const summaryData = JSON.parse(localStorage.getItem('summaryData'));
    if (summaryData.data.shipment_status === 3) {

      calculateETA()
      const intervalId = setInterval(calculateETA, 60000)
      setInervalId(intervalId);
      return () => {
        clearInterval(intervalId)
        setInterval(null)
      }
    }
  }, [])


  const getETA = () => {
    const riderLatLng = JSON.parse(localStorage.getItem('riderLatLng'))
    const summaryData = JSON.parse(localStorage.getItem('summaryData'))
    fetch('https://router.project-osrm.org/route/v1/driving/' + encodeURIComponent(riderLatLng.lng) + ',' +
      encodeURIComponent(riderLatLng.lat) + ';' + encodeURIComponent(summaryData.destinationLatlng.longitude) + ',' +
      encodeURIComponent(summaryData.destinationLatlng.latitude) + '?overview=false')
      .then(response => response.json())
      .then(async responseJson => {
        const distance = responseJson.routes[0].distance / 1000
        if (Number(summaryData.data.rider_speed) !== 0) {
          const ETA = parseFloat((distance * 60) / Number(summaryData.data.rider_speed)).toFixed()
          setDuration(ETA)
          console.log("ETA", ETA)
        }

      })
  }

  const refreshStatus = async () => {
    const summaryData = JSON.parse(localStorage.getItem('summaryData'))
    // setIsLoading(true);
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchId,
    }
    await ApiService.getChannelDetailsv3(data, token)
      .then(async result => {
        if (result?.data?.code == 0) {
          // setIsLoading(false);
          if ((result.data.data.shipment_status < 8)) {
            let dat = {
              orderMsg: result.data.data.orderMsg,
              riderName: result.data.data.riderName,
              shipment_status: result.data.data.shipment_status,
              riderMobNo: result.data.data.riderMobNo
            }
            setStatusData(dat);
          }
          if ((result.data.data.shipment_status === 1) || result.data.data.order_amt_to_be_refund > 0 || (result.data.data.refundAmount && result.data.data.refundAmount !== '0') || result.data.data.shipment_status === 5) {
            dispatch(setState({ key: "summaryData", value: { data: result.data.data, destinationLatlng: result.data.destinationLatlng, originLatlng: result.data.originLatlng } }));
          }
          if (result.data.data.refundAmount && result.data.data.refundAmount !== '0') {
            setRefunded(true)
          }
          if (result.data.data.shipment_status === 3) {
            if (!intervalId) {
              calculateETA()
              const intervalId = setInterval(calculateETA, 60000)
              setInervalId(intervalId);
            }
          }
        } else {
          console.log(result?.data?.message)
        }
      }
      )
  }

  return (
    <div>
      <div className='order-msg-container'>
        <p className='order-msg'>{statusData?.orderMsg}</p>
        <FontAwesomeIcon onClick={refreshStatus} className='status-refresh-icon' icon={faArrowsRotate} />
      </div>
      <Divider className='order-msg-eta-divider' />
      {refunded ? '' : (
        <div className='eta-call-container'>
          <div>
            <p className='rider-name'>{statusData?.riderName}</p>
            {statusData?.shipment_status === 3 && duration > 0 && duration !== 'Infinity' ? (
              <p className='order-msg'>Arriving in {duration} {`${duration == 1 ? 'min' : 'mins'}`}</p>
            ) : statusData?.shipment_status < 3 ? (
              <p className='order-msg'>Expected delivery in 30 - 45 mins</p>
            ) : statusData?.shipment_status === 3 && duration > 0 && duration >= 60 ? (
              <p className='order-msg'>Arriving in 59 mins</p>
            ) : null}
          </div>
          {/* <div > */}
          <a href={`tel:+91${statusData?.riderMobNo}`}>
            <div className='rider-call-container'>
              <img src={callIcon} className='rider-call-icon' alt='rider-call' />
            </div>
          </a>
          {/* </div> */}
        </div>
      )}
    </div>
  )
}
