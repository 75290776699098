// Layout Types
import { DefaultLayout } from "./../src/app/layouts";

// Route pages

import Homepage from "./../src/app/pages/Homepage";
import MapContainer from './other-catalog-pages/Map';
import AddressPage from './other-catalog-pages/AddressPage';
import OrderTracking from "./app/pages/ONDC-OrderTracking/OrderTracking";
import LoginPage from "./other-catalog-pages/LoginPage";
import CartPage from "./app/pages/CartPage/CartPage";
import OrderSummary from './app/pages/OrderSummary/OrderSummary';
import RewardsRoutes from "./app/pages/Rewards/RewardsRoutes";

const routes = [
  {
    path: localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/rewards` : '/rewards',
    layout: DefaultLayout,
    component: RewardsRoutes,
  },
  {
    path: localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/order-summary` : '/order-summary',
    layout: DefaultLayout,
    component: OrderSummary,
  },
  {
    path: localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/cart` : '/cart',
    layout: DefaultLayout,
    component: CartPage,
  },
  {
    path: `/map`,
    layout: DefaultLayout,
    component: MapContainer,
  },
  
  {
    path: `/address`,
    layout: DefaultLayout,
    component: AddressPage,
  },
  {
    path: `/login`,
    layout: DefaultLayout,
    component: LoginPage,
  },
  {
    path: localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}` : '/',
    layout: DefaultLayout,
    component: Homepage,
  }
];

export default routes;
