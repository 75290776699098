import React, { useEffect, useState } from 'react'
import { ClipLoader } from "react-spinners";
import { ToastContainer, toast } from 'react-toastify';
import ApiService from '../app/utils/ApiService';
import { Divider, TextField } from "@mui/material";
import chattybaoLogo from '../../src/assets/chattybao-logo.png'
import './LoginPage.css'
import OtpInput from 'react-otp-input';
import ReactGA from 'react-ga';
import { Constants } from '../app/utils/Constants';

const ac = new AbortController();

const LoginPage = () => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState(null);
    const [agreementCheck, setAgreementCheck] = useState(true);
    const [otpVisible, setOtpVisible] = useState(false);
    const [otpVerified, setOtpVerified] = useState(true);
    const [loader, setLoader] = useState(false);
    const [count, setCount] = useState(null);
    const [autoRead, setAutoRead] = useState(false);
    const { requestTimedOutError, toastStyle } = Constants;

    useEffect(() => {
        if (otp?.length === 6 && autoRead) {
            verifyUserLoginOTP();
            setAutoRead(false);
        }
    }, [autoRead])

    const otpListener = () => {
        navigator.credentials.get({
            otp: { transport: ['sms'] },
            signal: ac.signal
        }).then(otp => {
            console.log("otp", otp)
            setOtp(otp.code);
            // verifyUserLoginOTP(otp.code);
            setAutoRead(true);
        }).catch(err => {
            console.log(err);
        });
    }

    const handlePhoneNumber = (value) => {
        if (value.length < 11) {
            setPhoneNumber(value);
        }
    }

    const sendUserLoginOTP = async () => {
        const data = {
            mobileNumber: phoneNumber,
            firebaseId: "null",
            os: "null",
            osVer: "null",
            platform: 'WEB',
            userFcmToken: null,
            appVersion: 0,
            isFromWeb: true,
        };
        try {
            setLoader(true);
            const result = await ApiService.sendUserLoginOTP(data);
            console.log("result", result)
            if (result?.code === requestTimedOutError) {
                toast(result.message, { autoClose: 500 });
            } else if (result?.data?.code === 0) {
                otpListener();
                toast(result?.data?.message, { autoClose: 500 });
                setOtpVisible(true);
                startResendTimer();
            } else if (result?.message === 'Request failed with status code 429') {
                toast('Try after 30 minutes', { autoClose: 500 });
            } else {
                toast(result.data.message, { autoClose: 500 });
            }
        } catch (error) {
            console.log(error);
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
                'event': 'website_login_fail',
                'phoneNumber': phoneNumber,
            });
            ReactGA.event({
                action: 'Website Login Fail',
                label: 'Login Failed',
                errorMessage: error?.message
            });
        } finally {
            setLoader(false);
        }

    }

    const verifyUserLoginOTP = async (passedOtp) => {
        const data = {
            mobileNumber: phoneNumber,
            otp: otp,
        };
        try {
            setLoader(true);
            const result = await ApiService.verifyUserLoginOTP(data);
            if (result.code === requestTimedOutError) {
                toast(result.message, { autoClose: 500 });
            } else if (result.data.code === 0) {
                toast(result.data.message, { autoClose: 500 });
                webUserLogin()
            } else {
                toast(result.data.message, { autoClose: 500 });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    const webUserLogin = async () => {
        const data = {
            mobileNumber: "91" + phoneNumber,
            firebaseId: "null",
            os: "null",
            osVer: "null",
            platform: 'WEB',
            userFcmToken: null,
            appVersion: 0,
        };
        try {
            setLoader(true);
            const result = await ApiService.webUserLoginNew(data);
            if (result.code === requestTimedOutError) {
                toast(result.message, { autoClose: 500 });
            } else if (result.data.code === 0) {
                console.log("loginRes", result);
                localStorage.setItem("User", phoneNumber);
                localStorage.setItem("sessionId", result.data.data.sessionId);
                postCrossDomainMessage(phoneNumber, result.data.data.sessionId);
                const ac = new AbortController();
                ac.abort();
            } else {
                toast(result.data.message, { autoClose: 500 });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    function postCrossDomainMessage(phoneNumber, sessionToken) {
        const data = {
            phoneNumber: phoneNumber,
            sessionToken: sessionToken
        }
        window.parent.postMessage({ type: 'storeData', data }, '*');
    }

    function convertToTwoDigit(number) {
        return number < 10 ? '0' + number : number;
    }

    const startResendTimer = () => {
        let count = 45
        setCount(count);
        let timer = setInterval(() => {
            if (count === 0) {
                clearInterval(timer)
                setCount(null);
            } else {
                count = count - 1
                setCount(convertToTwoDigit(count))
            }
        }, 1000)
    }

    const resendOtp = async () => {
        const data = {
            mobileNumber: phoneNumber,
            isFromWeb: true,
        };
        try {
            otpListener();
            setLoader(true);
            const result = await ApiService.resendUserLoginOTP(data);
            if (result?.code === requestTimedOutError) {
                toast(result?.message, { autoClose: 500 });
            } else if (result?.data?.code === 0) {
                toast(result?.data?.message, { autoClose: 500 });
                startResendTimer();
                setOtpVisible(true);
            } else if (result?.message === 'Request failed with status code 429') {
                toast('Try after 30 minutes', { autoClose: 500 });
            } else {
                toast(result?.data?.message, { autoClose: 500 });
            }
        } catch (error) {
            console.log(error);
        } finally {
            setLoader(false);
        }
    }

    return (
        <div className='login-page-container'>
            <ToastContainer className="toast-container" hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
            <div className='sub-container'>
                <div className='logo-text-container'>
                    <div>
                        <img src={chattybaoLogo} className='cb-login-logo' alt='cb-logo' />
                        <p className='secure-login-text'>Secure Login</p>
                    </div>
                    <p className='powered-by-text-login'>This website is powered by ChattyBao</p>
                </div>
                {!otpVisible ? (
                    <div className='login-whole-container'>

                        {/* <label className="mobile_number_text pl-4">ChattyBao secure login</label> */}
                        <center>
                            <TextField
                                InputProps={{
                                    inputProps: { style: { WebkitAppearance: "none" } },
                                    endAdornment: null
                                }}
                                type='number'
                                value={phoneNumber}
                                className="mobile-number-input"
                                label="Enter 10 digit Mobile Number"
                                onChange={(event) => handlePhoneNumber(event.target.value)}
                            />
                            {/* <input value={phoneNumber} className="number_input" type="number" onKeyDown={handleKeyDown} /> */}

                        </center>
                        <div className='agreement-container'>
                            <input className="agreement_check_box" checked={agreementCheck} onChange={() => setAgreementCheck(!agreementCheck)} type="checkbox" />
                            <p className="agreement_text pl-10p">
                                I agree to
                                <span onClick={() => window.open("https://chattybao.com/user-agreement")} className="loginpage_link">
                                    Terms and Conditions</span> and <span onClick={() => window.open("https://chattybao.com/privacy-policy")} className="loginpage_link">Privacy Policy
                                </span>
                            </p>
                        </div>
                        <center>
                            <button className={agreementCheck && phoneNumber.length === 10 ? "agreement_continue_btn" : "agreement_continue_btn_disabled"} onClick={loader ? null : sendUserLoginOTP} disabled={agreementCheck && phoneNumber.length === 10 ? false : true}>{loader ? (<ClipLoader color="#36d7b7" />) : "Get OTP"}</button>
                        </center>
                    </div>
                ) : (
                    <div className='otp-whole-container'>
                        <div>
                            <label className="mobile_number_text mb-0">Please enter 6 digit OTP</label>
                            <p className="senton_mobile_text">sent to {phoneNumber}</p>
                            <center>
                                {/* <input value={otp} className="number_input" type="number" onChange={(event) => { handleOtpInput(event.target.value); setOtpVerified(true); }} /> */}
                                {/* <TextField type='number' value={otp} className="mobile-number-input" label="Enter 6 digit OTP" onChange={(event) => handleOtpInput(event.target.value)} /> */}
                                <OtpInput
                                    value={otp}
                                    onChange={setOtp}
                                    numInputs={6}
                                    // renderSeparator={<span></span>}
                                    renderInput={(props) => <input {...props} />}
                                    skipDefaultStyles={true}
                                    inputStyle={'otp-input-box'}
                                    inputType='number'
                                    containerStyle={'otp-container-style'}
                                />
                            </center>
                            {!otpVerified && (
                                <p className="otp_wrong_text">Entered wrong OTP</p>
                            )}
                            <Divider className='login-divider' />
                            <p className='havent-received-text'>Haven't received OTP yet?</p>
                            {count ? (
                                <div className='resend-otp-container'>
                                    <p className='otp-resend-text-count'>Resend in</p>
                                    <p className='resend-count-text'>{count ? `00.${count}` : ''}</p>
                                </div>
                            ) : (
                                <p onClick={loader ? null : resendOtp} className="otp-resend-text">Resend</p>
                            )}
                        </div>

                        <div className='verify-btn-container'>
                            <button disabled={otp?.length !== 6} className={(otp && otp.length === 6) ? "verify-btn" : "verify-btn verify-btn-disabled"} onClick={loader ? null : verifyUserLoginOTP}>{loader ? (<ClipLoader color="#36d7b7" />) : "Verify"}</button>
                        </div>

                    </div>
                )}

                <div hidden id="recapcha-container"></div>
            </div>
        </div>
    )
}

export default LoginPage
