import { APIConfig } from "./apiConfig";
import { ApiCallGet, ApiCallPost } from './ApiCall';

const ApiService = {
    getMerchantDataForCatalogue: async (data, token) => {
        const { getMerchantDataForCatalogue, baseUrl } = APIConfig;
        const url = baseUrl + getMerchantDataForCatalogue
        let headers = {};
        if (token !== null) {
            headers = {
                token,
            }
        } else {
            headers = {
                'Content-Type': 'application/json',
            }
        }

        return ApiCallPost(url, data, headers)
    },

    getaddresses: async (token) => {
        const { getUserAddresses, baseUrl } = APIConfig;
        const url = baseUrl + getUserAddresses
        const headers = {
            token
        }
        return ApiCallGet(url, headers)
    },
    getUserLogisticCharge: async (data, token) => {
        const { getOrderPaymentDetails, baseUrl2 } = APIConfig;
        const url = baseUrl2 + getOrderPaymentDetails
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    gMapsApi: async (lat, lng) => {
        const { gMapCall, gmapApiKey } = APIConfig;
        return fetch(gMapCall + lat + ',' + lng + '&key=' + gmapApiKey)
    },
    makePayment: async (data, token) => {
        const { makePayment, baseUrl } = APIConfig;
        const url = baseUrl + makePayment
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    webUserLogin: async (data) => {
        const { webUserLogin, baseUrl } = APIConfig;
        const url = baseUrl + webUserLogin
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },
    updateUserAddress: async (data, token) => {
        const { updateUserAddress, baseUrl1 } = APIConfig;
        const url = baseUrl1 + updateUserAddress
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    getChannelDetailsv3: async (data, token) => {
        const { getChannelDetailsv3, baseUrl2 } = APIConfig;
        const url = baseUrl2 + getChannelDetailsv3
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    getMerchUserChannel: async (data, token) => {
        const { getMerchUserChannel, baseUrl } = APIConfig;
        const url = baseUrl + getMerchUserChannel
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    trackOrder: async (orderId) => {
        const { trackOrder, baseUrl } = APIConfig;
        const url = baseUrl + trackOrder + `?orderId=${orderId}`
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallGet(url, headers)
    },

    getMerchantS1Categories: async (data) => {
        console.log("Called mer")
        const { getMerchantS1Categories, baseUrl } = APIConfig;
        const url = baseUrl + getMerchantS1Categories
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getS1CategoriesProducts: async (data) => {
        const { getS1CategoriesProducts, baseUrl } = APIConfig;
        const url = baseUrl + getS1CategoriesProducts
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    searchMerchantCatalogue: async (data) => {
        const { searchMerchantCatalogue, baseUrl } = APIConfig;
        const url = baseUrl + searchMerchantCatalogue
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getUserAddressFromLatLong: async (data, token) => {
        const { getUserAddressFromLatLong, baseUrl } = APIConfig;
        const url = baseUrl + getUserAddressFromLatLong
        const headers = {
            token
        }
        return ApiCallPost(url, data, headers)
    },
    getHelpDeskNumber: async (token) => {
        const { getHelpDeskNumber, baseUrl } = APIConfig;
        const url = baseUrl + getHelpDeskNumber
        const headers = {
            token
        }
        return ApiCallGet(url, headers)
    },

    generateHashcodeForWeb: async (data) => {
        const { generateHashcodeForWeb, baseUrl } = APIConfig;
        const url = baseUrl + generateHashcodeForWeb
        const headers = {
            'Content-Type': 'application/json'
        }
        return ApiCallPost(url, data, headers)
    },

    payU: async (data) => {
        const url = 'https://secure.payu.in/_payment'
        const headers = {
            'Content-Type': 'application/x-www-form-urlencoded',
            'accept': 'application/json'
        }
        return ApiCallPost(url, data, headers)
    },

    getProductDetails: async (data) => {
        const { getProductDetails, baseUrl } = APIConfig;
        const url = baseUrl + getProductDetails
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    sendUserLoginOTP: async (data) => {
        const { sendUserLoginOTP, baseUrl } = APIConfig;
        const url = baseUrl + sendUserLoginOTP
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    verifyUserLoginOTP: async (data) => {
        const { verifyUserLoginOTP, baseUrl } = APIConfig;
        const url = baseUrl + verifyUserLoginOTP
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    resendUserLoginOTP: async (data) => {
        const { resendUserLoginOTP, baseUrl } = APIConfig;
        const url = baseUrl + resendUserLoginOTP
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    webUserLoginNew: async (data) => {
        const { webUserLoginNew, baseUrl2 } = APIConfig;
        const url = baseUrl2 + webUserLoginNew
        const headers = {
            'Content-Type': 'application/json',
        }
        return ApiCallPost(url, data, headers)
    },

    getRedeemablePoints: async (data, token) => {
        const { getRedeemablePoints, baseUrl } = APIConfig;
        const url = baseUrl + getRedeemablePoints
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    createPaymentLinkForInStore: async (data, token) => {
        const { createPaymentLinkForInStore, baseUrl } = APIConfig;
        const url = baseUrl + createPaymentLinkForInStore
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    getLoyaltyOrderDetailsForUser: async (data, token) => {
        const { getLoyaltyOrderDetailsForUser, baseUrl } = APIConfig;
        const url = baseUrl + getLoyaltyOrderDetailsForUser
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    getCustomerMerchantLoyaltyInfo: async (data, token) => {
        const { getCustomerMerchantLoyaltyInfo, baseUrl } = APIConfig;
        const url = baseUrl + getCustomerMerchantLoyaltyInfo
        const headers = {
            token,
        }
        return ApiCallPost(url, data, headers)
    },

    getLoyaltyTransactionsForUser: async (data, token) => {
        const { getLoyaltyTransactionsForUser, baseUrl } = APIConfig;
        const url = baseUrl + getLoyaltyTransactionsForUser + '?limit=' + data.limit + '&offset=' + data.offset + '&merchantId=' + encodeURIComponent(data.merchantId);
        const headers = {
            token,
        }
        return ApiCallGet(url, headers)
    },

}
export default ApiService
