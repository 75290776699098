import axios from 'axios'

// const TAG = 'ApiCall'

export const ApiCallPost = async (url, parameters, headers) => {
  try {
    const response = await axios.post(url, parameters, { headers: headers, timeout: 30000, timeoutErrorMessage: 'API request timed out'})
    return response
  } catch (error) {
    return error
  }
}

export const ApiCallGet = async (url, headers) => {
  try {
    const response = await axios.get(url, { headers: headers, timeout: 30000, timeoutErrorMessage: 'API request timed out'})
    return response
  } catch (error) {
    return error
  }
}
