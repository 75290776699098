import React, { useState, useEffect, useCallback, useRef } from "react";
import { useHistory, BrowserRouter as Router } from "react-router-dom";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { setState } from "../../redux/reduxStore.js";
import { useDispatch, useSelector } from "react-redux";
import ReactGA from 'react-ga';
import { BeatLoader, ClipLoader, MoonLoader } from "react-spinners";
import LoadingSpinner from "../loadingSpinner/loadingSpinner.js";

import "./Index.css";
import '../../pages/ProductPage/ProductPage.css';
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
} from "@mui/material";
import edit_icon from "../../../assets/edit.png";
import close_icon from "../../../assets/close.png";
import discount_tag from '../../../assets/Green_Discount_Tag.png';
import bestSeller_tag from '../../../assets/Bestseller_Tag.png';
import discount_tag_hor from '../../../assets/discount_tag_hor.png';
import confirm_btn from '../../../assets/Confirm_Button.png';
import close_btn from '../../../assets/close_btn.png';
import variant_bestseller from '../../../assets/variant_bestseller.png';
import down_arrow from '../../../assets/down_arrow.png';
import menu_icon from '../../../assets/menu_icon.png';
import share_icon from '../../../assets/share_icon.png';
import up_arrow from '../../../assets/up_arrow.png';
import order_delivered_grey from '../../../assets/order_delivered_grey.png';
import order_delivered_green from '../../../assets/order_delivered_green.png';
import order_placed_green from '../../../assets/order_placed_green.png';
import flourishLeft from '../../../assets/flourish-left.png'
import flourishRight from '../../../assets/flourish-right.png'
import payment_grey from '../../../assets/payment_grey.png';
import payment_green from '../../../assets/payment_green.png';
import homepageWhatsappBlack from '../../../assets/homepage-whatsapp-black.png';
import imageNotAvailable from '../../../assets/image-not-available.png';
import freeDeliveryBanner from '../../../assets/free-delivery-banner.png';
import whatsappGreenIcon from '../../../assets/whatsapp-greenborder.png';
import whatsappWhiteIcon from '../../../assets/homepage-whatsapp.png';
import callWhiteIcon from '../../../assets/white-call-btn.png';
import callGreenIcon from '../../../assets/call-green.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import CloseIcon from "@mui/icons-material/Close";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { data } from "jquery";
import useStyles from "./styles";
import { TransformComponent, TransformWrapper } from "@pronestor/react-zoom-pan-pinch";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import veg_icon from '../../../../src/assets/veg.png';
import non_veg_icon from '../../../../src/assets/nonveg.png';
import egg_icon from '../../../../src/assets/egg.png';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ApiService from '../../utils/ApiService';
import { debounce } from 'lodash'
import { Constants } from "../../utils/Constants.js";
import { APIConfig } from "../../utils/apiConfig.js";
import TextOverFlow from "./TextOverFlow.js";
import { commonFunctions } from "../../utils/CommonFunctions.js";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variantWidth: false
};

const carousel = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 3000, // 3 seconds
  appendDots: dots => (
    <ul style={{ margin: '0' }}>
      {dots.slice(0, 4)} {/* Display only the first 4 dots */}
    </ul>
  ),
};

ReactGA.initialize('UA-115105611-2');
const Page = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    searchInput,
    isFruitsAndVegMerchant,
    PhoneNumber,
    gridType,
    businessName,
    merchId,
    cartVisible,
    setCartVisible,
    userOfferBanner,
    menuOption,
    setMenuOption,
  } = props;

  const history = useHistory();
  const [modalVisible, setModalVisible] = useState(false);
  const [productDetailVisible, setProductDetailVisible] = useState(false);
  const [bestSeller, setBestSeller] = useState([]);;
  const [modificationData, setModificationData] = React.useState([]);
  const [instruction, setInstruction] = useState();
  const [instructionIndex, setInstructionIndex] = useState();
  const [productId, setProductId] = useState("");
  const [productImage, setProductImage] = useState([]);
  const [productName, setProductName] = useState("");
  const [pdBrand, setPdBrand] = useState("");
  const [productCount, setProductCount] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [productDesc, setProductDesc] = useState("");
  const [productAvailabilityData, setProductAvailabilityData] = useState('');
  const [productDiscPrice, setProductDiscPrice] = useState('');
  const [pdOffer, setPdOffer] = useState('');
  const [variantsVisible, setVariantsVisible] = useState(false);
  const [variants, setVariants] = useState([]);
  const [productVariantExists, setProductVariantExists] = useState(false);
  const [varDataFromPd, setVarDataFromPd] = useState([]);
  const [instructionFromHome, setInstructionFromHome] = useState(null);
  const [variantIndex, setVariantIndex] = useState(null);
  const [menuModalVisible, setMenuModalVisible] = useState(false)
  const [ifBestSeller, setIfBestSeller] = useState(false);
  const [variantFoodType, setVariantFoodType] = useState('');
  const [pdVariantLength, setPdVariantLength] = useState('');
  const [variantIsBestseller, setVariantIsBestseller] = useState(false);
  const [logisticData, setLogisticData] = useState({});
  const [orderPlaced, setOrderPlaced] = useState(false);
  const [showOrderSummary, setShowOrderSummary] = useState(false);
  const [cartDat, setCartDat] = useState([]);
  const [orderStatus, setOrderStatus] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [catalogLoader, setCatalogLoader] = useState(false);
  const [showDownloadBanner, setShowDownloadBanner] = useState(true);
  const [customInstructions, setCustomInstructions] = useState(['']);
  const [s1CategoryList, setS1CategoryList] = useState([]);
  // const { getShortId, getLongId } = commonFunctions;
  const [selectedS1, setSelectedS1] = useState({
    category: '',
    categoryId: ''
  });
  const [cartData, setCartData] = useState([]);
  const [instructionFromCart, setInstructionFromCart] = useState('');
  const [offerBanner, setOfferBanner] = useState(userOfferBanner);
  const { aboutUsPopupStyle, toastStyle, userCashbackTermsPage } = Constants;
  const { iframeUrl } = APIConfig
  const urls = window.location.href;
  const aliasname1 = urls.substring(urls.lastIndexOf("/") + 1);
  const alias = aliasname1.split("#");
  const aliasname2 = alias[0].split("?");
  var url = new URL(urls);
  var userApp = url.searchParams.get("isUserApp");
  const aliasname = aliasname2[0];
  const s1CategoryRef = useRef(null);
  const descriptionRef = useRef(null);
  const categoryBarRef = useRef(null);
  const totalPrice = useSelector((state) => state.counter.totalPrice);
  const totalCount = useSelector((state) => state.counter.totalCount);
  const iframeLoader = useSelector((state) => state.counter.iframeLoader);
  const loginPage = useSelector((state) => state.counter.loginPage);
  const [bannerImages, setBannerImages] = useState([userOfferBanner, freeDeliveryBanner])
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const whatsappOpen = useSelector((state) => state.counter.whatsappOpen);
  const openPhone = useSelector((state) => state.counter.openPhone);
  const [htmlContent, setHtmlContent] = useState('');

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Merchant Website Clicked',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'Merchant Website Clicked',
      label: 'Website Clicked',
      dimension1: merchId,
      dimension2: businessName
    });
    if (window.location.pathname.includes('/product')) {
      // const path = window.location.pathname;
      // const newPath = path.split('/').slice(0, -1).join('/');
      // window.location = newPath;
      // history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/product` : '/product')
    }
    dispatch(setState({ key: "loginPage", value: false }));
  }, [])

  useEffect(() => {
    // opening cart based on value change from Homepage
    if (cartVisible) {
      if (!orderPlaced) {
        setCartVisible(false);
        if (localStorage.getItem("User")) {
          handleviewcart();
        } else {
          // setLoginPage(true);
          // setIframeLoader(true);
          dispatch(setState({ key: "loginPage", value: true }));
          dispatch(setState({ key: "iframeLoader", value: true }))
        }
      }
    }
  }, [cartVisible])

  useEffect(() => {
    const container = descriptionRef.current;
    if (container) {
      const lineHeight = parseInt(window.getComputedStyle(container).lineHeight);
      const maxHeight = lineHeight * 2;
      setIsOverflowing(container.scrollHeight > maxHeight);
    }
  }, [bestSeller]);

  useEffect(() => {  // iframe listener
    const handleMessage = (event) => {
      if (event.data && event.data.type === 'storeData') {
        const data = event.data.data;
        // localStorage.setItem('yourDataKey', data);
        console.log("Data", data)
        if (data) {
          // setLoginPage(false);
          dispatch(setState({ key: "loginPage", value: false }))
          localStorage.setItem("User", data.phoneNumber);
          localStorage.setItem("sessionId", data.sessionToken);
          getMerchUserChannel("fromLogin")
          console.log("getmerchant EXECUTED")
          getMerchantDet(data.sessionToken)
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {  // automatic page refresh to update status when coming from payment link
    const getLiveOrder = () => {
      if (!document.hidden) {
        getChannelDetailsv3()
      }
    };

    document.addEventListener('visibilitychange', getLiveOrder);

    return () => {
      document.removeEventListener('visibilitychange', getLiveOrder);
    };
  }, []);

  const searchCatalogue = async (input) => { // api call for product search
    setCatalogLoader(true);
    const data = {
      merchantId: merchId,
      searchKeyword: input,
      available: true,
      isFromApp: false,
    }
    try {
      const result = await ApiService.searchMerchantCatalogue(data);
      if (result?.data?.code == 0) {
        setBestSeller(result.data.data)
      } else if (result.data.message === 'No Data Available') {
        setBestSeller([]);
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setCatalogLoader(false);
    }
  }

  const seachHandler = (input) => {
    handler(input)
  }

  const handler = useCallback(debounce((input) => {
    if (input === '') {
      getS1Categories();
    } else {
      searchCatalogue(input)
    }
  }, 1000), [])

  useEffect(() => {  // handling search when search input changes
    // if (searchInput !== '') {
    let timeoutId;

    const handleSearch = () => {
      seachHandler(searchInput)
      // searchCatalogue function here
      // searchCatalogue()
    };

    // Clear previous timeout (if any) when searchInput changes
    clearTimeout(timeoutId);

    // Set a new timeout for 300 milliseconds
    timeoutId = setTimeout(() => {
      handleSearch();
    }, 300);

    return () => {
      // Cleanup: clear the timeout when the component unmounts or when searchInput changes
      clearTimeout(timeoutId);
    };
    // } else {
    //   getS1Categories()
    // }

  }, [searchInput]);

  const getS1Categories = async () => {  // fetch s1 categories
    setCatalogLoader(true);
    const data = {
      merchantID: merchId,
      isFromApp: false
    }
    try {
      const result = await ApiService.getMerchantS1Categories(data);
      if (result?.data?.code == 0) {
        if (menuOption === 1 || menuOption === 2) {
          setIsLoading(true);
          setTimeout(() => {
            setIsLoading(false);
            scrollToS1Category();
            setMenuOption(0)
          }, 2000)
        }

        if (gridType === 1) {
          const updatedCategories = result.data.s1Categories.map((category, index) => {
            return {
              ...category,
              show: index === 0 ? true : false,
              apiCalled: index === 0 ? true : false
            };
          });
          setS1CategoryList(updatedCategories);
          setBestSeller(result.data.data);
        } else {
          setS1CategoryList(result.data.s1Categories);
          const currentPath = window.location.pathname;
          if (currentPath.includes('/cid=')) {
            const cidMatch = currentPath.match(/\/cid=([^/]+)/);
            // let hash = decodeURIComponent(window.location.hash.substring(1))
            // console.log("HASH", decodeURIComponent(window.location.hash.substring(1)))
            console.log("cidMatch", cidMatch)
            // const longId = getLongId(cidMatch[1])
            let hashS1 = result.data.s1Categories.find((data) => data.id === cidMatch[1])
            let hashS1Index = result.data.s1Categories.findIndex((data) => data.id === cidMatch[1])
            setSelectedS1({ category: hashS1.category })
            if (hashS1Index !== -1) {
              await getS1CategoryProducts(hashS1.category, hashS1.id, hashS1Index);
            }
          } else {
            setBestSeller(result.data.data)
            setSelectedS1({ category: result.data.s1Categories[0].category })
          }
        }


      } else {
        console.log("Data fetch failed")
      }
    } catch (error) {
      alert(error.message)
    } finally {
      setCatalogLoader(false);
    }
  }

  // Showing products for Menu option clicks
  useEffect(() => {
    if (menuOption === 1) {
      if (s1CategoryList.length !== 0) {
        if (s1CategoryList.some(item => item.category === 'Best Seller')) {
          if (gridType !== 1) {
            handleS1CategoryClick(s1CategoryList[0].category, s1CategoryList[0].categoryId)
            setMenuOption(0)
            setIsLoading(true);
            setTimeout(() => {
              setIsLoading(false);
              scrollToS1Category();
            }, 2000)
          }
        }
      } else {
        // setIsLoading(true);
        // setTimeout(() => {
        //   setIsLoading(false);
        //   scrollToS1Category();
        //   setMenuOption(0)
        // }, 2000)
      }
    } else if (menuOption === 2) {
      setIsLoading(true);
      if (gridType !== 1) {
        setTimeout(() => {
          setIsLoading(false);
          scrollToS1Category();
          setMenuOption(0)
        }, 2000)
      } else {
        setTimeout(() => {
          setIsLoading(false);
          scrollToS1Category();
          setMenuOption(0)
        }, 2000)
      }

    }
  }, [menuOption])


  useEffect(() => {  // updating the modificationdata state whenever products are changed
    var filteredArray = bestSeller
    // && bestSeller.filter(function (el) { // filtering out unavailable products
    //   return el.status === 0;
    // });
    // filteredArray = filteredArray.sort(function (a, b) {
    //   if (a.id < b.id) {
    //     return 1;
    //   }
    //   if (a.id > b.id) {
    //     return -1;
    //   }
    //   return 0;
    // });

    const transformed =
      filteredArray &&
      filteredArray.filter(data => data !== null)
        .map(({ id, country, desc, image_url, item_name, rate, unit, count, offer, discounted_rate, foodType, variants, category, bestseller, brandName, is_product_available, avail_msg, status }) => ({
          id: id,
          country: country,
          item_name: item_name,
          desc: desc,
          image_url: image_url,
          price: rate,
          unit: unit,
          count: 0,
          instruction: "",
          offer: offer,
          discounted_rate: discounted_rate,
          foodType: foodType,
          variants: variants,
          s1category: category,
          bestseller: bestseller,
          brandName: brandName,
          is_product_available: is_product_available,
          avail_msg: avail_msg,
          status: status

        }));

    for (let i = 0; i < transformed.length; i++) {
      if (transformed[i].variants && transformed[i].variants.length !== 0) {
        for (let j = 0; j < transformed[i].variants.length; j++) {
          transformed[i].variants[j].count = 0
          transformed[i].variants[j].instruction = ""
        }
      }
    }

    // sorting in alphabetical order
    const datass =
      transformed &&
      transformed.sort(function (a, b) {
        if (a.item_name < b.item_name) {
          return 1;
        }
        if (a.item_name > b.item_name) {
          return -1;
        }
        return 0;
      });

    const dat = datass && datass.sort(function (a, b) {
      if ((a.discounted_rate !== undefined || a.discounted_rate !== null || a.discounted_rate !== "") && (b.discounted_rate === undefined || b.discounted_rate === null || b.discounted_rate === "")) {
        return -1
      }
      if ((a.discounted_rate === undefined || a.discounted_rate === null || a.discounted_rate === "") && (b.discounted_rate !== undefined || b.discounted_rate !== null || b.discounted_rate !== "")) {
        return 1
      }
      if ((a.discounted_rate !== undefined || a.discounted_rate !== null || a.discounted_rate !== "") && (b.discounted_rate !== undefined || b.discounted_rate !== null || b.discounted_rate !== "")) {
        if (a.item_name < b.item_name) {
          return -1;
        }
        if (a.item_name > b.item_name) {
          return 1;
        }
      }
      if ((a.discounted_rate === undefined || a.discounted_rate === null || a.discounted_rate === "") && (b.discounted_rate === undefined || b.discounted_rate === null || b.discounted_rate === "")) {
        if (a.item_name < b.item_name) {
          return -1;

        }
        if (a.item_name > b.item_name) {
          return 1;
        }
      }
      return 0
    })

    const cart = (JSON.parse(localStorage.getItem("cartData")));
    if (cart) {
      // comparing cart array with main array to update count
      const updatedArray = dat.map((item1) => {
        const matchingItem = cart.find((item2) => item1.id === item2.id);
        if (matchingItem) {
          return matchingItem;
        }

        return item1;
      });
      console.log('updatedArray : ', updatedArray)
      setModificationData(updatedArray);
      setCartData(cart);
    } else {
      setModificationData(dat);
      dispatch(setState({ key: "totalCount", value: 0 }));
    }

    // Calculations for cart
    if (cart) {
      let filterData = cart.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
      let countsum = filterData.map((data) => data.count);
      let varcountsum = [];
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            varcountsum.push(cart[i].variants[j].count)
          }
        }

      }
      var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
      var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
      Sumcount = Sumcount + varSumcount;
      dispatch(setState({ key: "totalCount", value: Sumcount }));
      let varPrice = [];
      let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            if (cart[i].variants[j].count > 0) {
              if (cart[i].variants[j].variantDisPrice !== undefined && cart[i].variants[j].variantDisPrice !== null && cart[i].variants[j].variantDisPrice !== '') {
                varPrice.push(cart[i].variants[j].count * parseFloat(cart[i].variants[j].variantDisPrice))
              } else {
                varPrice.push(cart[i].variants[j].count * parseFloat(cart[i].variants[j].variantPrice))
              }
            }
          }
        }

      }
      var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
      var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
      var Sumprice = otherPrice + ttPrice;
      dispatch(setState({ key: "totalPrice", value: Sumprice }));

      let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
      var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
      let oldvarcountprice = [];
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            let data = (cart[i].variants[j])
            oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
          }
        }

      }
      var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
      oldSumprice = oldSumprice + oldvarSumprice;
      dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
      dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
      if (Sumcount > 0) {  // To not display app download banner when something added to cart
        setShowDownloadBanner(false);
      }
    }

    let addressUpdated = localStorage.getItem("addressUpdated");
    if (addressUpdated !== null && addressUpdated === '1') {
      console.log("THISSUM", Sumprice)
      // getaddressesAndLogistics(Sumprice);
      localStorage.removeItem("addressUpdated");
    } else {
      // getaddresses();
    }

  }, [bestSeller]);

  useEffect(() => {
    if (localStorage.getItem("User")) {
      getMerchUserChannel(); //api call to check for latest order
    } else {
      getModifiedData();
    }
  }, []);

  const addCountForVariant = (count, id, parentId, value) => {
    setShowDownloadBanner(false);
    count = count + 1
    let cart = cartData;
    const parentIndex = modificationData.map((item) => item.id).indexOf(parentId);
    const varIndex = modificationData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
    modificationData[parentIndex].variants[varIndex].count = count
    modificationData[parentIndex].count++
    console.log('cart modification : ', cart);
    const cartIndex = cart.map((item) => item.id).indexOf(parentId);
    if (cartIndex !== -1) {

      cart[cartIndex].count = modificationData[parentIndex].count
      cart[cartIndex].variants[varIndex].count = count
    } else {
      cart.push(modificationData[parentIndex])
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    setProductCount(productCount + 1)

    let filterdata = [...modificationData];
    setModificationData(filterdata);

    //  Calulations
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }

    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));

    let varcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
        }
      }

    }
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
    var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    Sumprice = Sumprice + varSumprice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }));

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
  };

  const decreaseCountForVariant = (count, id, parentId, value) => {
    count = count - 1
    let cart = cartData;
    const parentIndex = modificationData.map((item) => item.id).indexOf(parentId);
    const varIndex = modificationData[parentIndex].variants.map((item) => item.varientId).indexOf(id);
    const cartIndex = cart.map((item) => item.id).indexOf(parentId);
    if (cartIndex !== -1) {
      if (modificationData[parentIndex].count === 1) {
        cart.splice(cartIndex, 1);
        modificationData[parentIndex].count--
        modificationData[parentIndex].variants[varIndex].count--
      } else {
        console.log("modPar", modificationData[parentIndex].count)
        console.log("modVar", modificationData[parentIndex].variants[varIndex].count)
        cart[cartIndex].count = modificationData[parentIndex].count
        cart[cartIndex].variants[varIndex].count = count
        modificationData[parentIndex].count--

      }
    } else {
      cart.push(modificationData[parentIndex])
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));

    setProductCount(productCount - 1)
    let filterdata = [...modificationData];
    setModificationData(filterdata);

    //  Calulations
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));

    let varcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          varcountprice.push(data.count > 0 ? ((data.variantDisPrice !== null && data.variantDisPrice !== "" && data.variantDisPrice !== undefined) ? data.count * parseFloat(data.variantDisPrice) : data.count * parseFloat(data.variantPrice)) : 0)
        }
      }

    }
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
    var varSumprice = varcountprice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    Sumprice = Sumprice + varSumprice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }))

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
  };

  const addCount = (count, id, value) => {
    setShowDownloadBanner(false);
    window.dataLayer.push({
      'event': 'Add to Cart',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'Add to Cart',
      label: 'Adding products to cart',
      dimension1: merchId,
      dimension2: businessName,
    });
    count = count + 1;
    const index2 = modificationData.map((item) => item.id).indexOf(id); // Found the object index

    modificationData[index2].count = count;
    let cart = cartData;
    const cartIndex = cart.map((item) => item.id).indexOf(id);
    console.log('cart modification 1 : ', cart[cartIndex]);

    if (cartIndex !== -1) {
      cart[cartIndex].count = count
      cart[cartIndex].rate = cart[cartIndex].price
      cart[cartIndex].merchant_id = merchId
      // cart[cartIndex].category = 
    } else {
      cart.push(modificationData[index2])
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    setProductCount(count);

    let filterdata = [...modificationData];
    setModificationData(filterdata);
    // localStorage.setItem("modifiedData", JSON.stringify(modificationData));

    //  Calulations
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));
    let varPrice = [];
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : (data.price === 0 ? 0 : data.count * parseFloat(data.price))) : 0));

    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          if (cartData[i].variants[j].count > 0) {
            if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
            } else {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
            }

          }
        }
      }

    }
    var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = otherPrice + ttPrice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }))

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
  };

  const decreaseCount = (count, id, value) => {
    count = count - 1;
    const index2 = modificationData.map((item) => item.id).indexOf(id); // Found the object index

    modificationData[index2].count = count;

    let cart = cartData;


    const cartIndex = cart.map((item) => item.id).indexOf(id);
    console.log('cart modification 2 : ', cart[cartIndex]);

    if (cartIndex !== -1) {
      if (count === 0) {
        cartData.splice(cartIndex, 1);
      } else {
        cart[cartIndex].count = count
        cart[cartIndex].rate = cart[cartIndex].price

      }
    } else {
      cart.push(modificationData[index2])
    }
    setCartData(cart)
    localStorage.setItem("cartData", JSON.stringify(cart));
    setProductCount(count);

    let filterdata = [...modificationData];
    setModificationData(filterdata);

    //  Calulations
    let filterData = cartData.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
    let countsum = filterData.map((data) => data.count);
    let varcountsum = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          varcountsum.push(cartData[i].variants[j].count)
        }
      }

    }
    var varSumcount = varcountsum.reduce((partialSum, a) => partialSum + a, 0);
    var Sumcount = countsum.reduce((partialSum, a) => partialSum + a, 0);
    Sumcount = Sumcount + varSumcount;
    dispatch(setState({ key: "totalCount", value: Sumcount }));
    let varPrice = [];
    let countprice = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));

    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          if (cartData[i].variants[j].count > 0) {
            if (cartData[i].variants[j].variantDisPrice !== undefined && cartData[i].variants[j].variantDisPrice !== null && cartData[i].variants[j].variantDisPrice !== '') {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantDisPrice))
            } else {
              varPrice.push(cartData[i].variants[j].count * parseFloat(cartData[i].variants[j].variantPrice))
            }

          }
        }
      }

    }
    var otherPrice = countprice.reduce((partialSum, a) => partialSum + a, 0);
    var ttPrice = varPrice.reduce((partialSum, a) => partialSum + a, 0);
    var Sumprice = otherPrice + ttPrice;
    dispatch(setState({ key: "totalPrice", value: Sumprice }))

    let oldcountprice = filterData.map((data) => (data.count > 0 ? data.count * parseFloat(data.price) : 0));
    var oldSumprice = oldcountprice.reduce((partialSum, a) => partialSum + a, 0);
    let oldvarcountprice = [];
    for (let i = 0; i < cartData.length; i++) {
      if (cartData[i].variants && cartData[i].variants.length !== 0) {
        for (let j = 0; j < cartData[i].variants.length; j++) {
          let data = (cartData[i].variants[j])
          oldvarcountprice.push(data.count > 0 ? (data.count > 0 ? data.count * parseFloat(data.variantPrice) : 0) : 0)
        }
      }

    }
    var oldvarSumprice = oldvarcountprice.reduce((partialSum, a) => partialSum + a, 0);
    oldSumprice = oldSumprice + oldvarSumprice;
    dispatch(setState({ key: "oldTotalPrice", value: oldSumprice }));
    dispatch(setState({ key: "savings", value: oldSumprice - Sumprice }));
  };

  const modalopen = (id, instruction, fromHomePage, fromCart) => {

    if (fromHomePage) {
      if (fromCart) {
        const cartIndex = cartData.map((item) => item.id).indexOf(id);
        setInstructionIndex(cartIndex);
        setInstructionFromCart(true);
      } else {
        const index2 = modificationData.map((item) => item.id).indexOf(id); // Found the object index
        setInstructionIndex(index2);
        setInstructionFromCart(false);
      }
      setInstructionFromHome(true);

    } else {
      if (fromCart) {
        for (let i = 0; i < cartData.length; i++) {
          if (cartData[i].variants && cartData[i].variants.length !== 0) {
            for (let j = 0; j < cartData[i].variants.length; j++) {
              let data = (cartData[i].variants[j])
              if (data.varientId === id) {
                setVariantIndex(j);
                setInstructionIndex(i)
                break;
              }
            }
          }
        }
        setInstructionFromCart(true);
      } else {
        for (let i = 0; i < modificationData.length; i++) {
          if (modificationData[i].variants && modificationData[i].variants.length !== 0) {
            for (let j = 0; j < modificationData[i].variants.length; j++) {
              let data = (modificationData[i].variants[j])
              if (data.varientId === id) {
                setVariantIndex(j);
                setInstructionIndex(i)
                break;
              }
            }
          }

        }
      }
      setInstructionFromHome(false);
      setInstructionFromCart(false);
    }
    setModalVisible(true);
    setInstruction(instruction);
  };

  const saveInstruction = () => {
    if (instructionFromHome) {
      if (instructionFromCart) {
        if (cartData[instructionIndex].variants && cartData[instructionIndex].variants.length !== 0) {
          cartData[instructionIndex].variants.map((variant => {
            variant.instruction = instruction;
          }))
        }
        cartData[instructionIndex].instruction = instruction;
        let filterdata = [...cartData];
        setCartData(filterdata);
        // localStorage.setItem("modifiedData", JSON.stringify(modificationData));
        setModalVisible(false);
      } else {
        if (modificationData[instructionIndex].variants && modificationData[instructionIndex].variants.length !== 0) {
          modificationData[instructionIndex].variants.map((variant => {
            variant.instruction = instruction;
          }))
        }
        modificationData[instructionIndex].instruction = instruction;
        let filterdata = [...modificationData];
        setModificationData(filterdata);
        // localStorage.setItem("modifiedData", JSON.stringify(modificationData));
        setModalVisible(false);
      }
    } else {
      if (instructionFromCart) {
        cartData[instructionIndex].variants[variantIndex].instruction = instruction;
        let filterdata = [...cartData];
        setCartData(filterdata);
        setModalVisible(false);
      } else {
        cartData[instructionIndex].variants[variantIndex].instruction = instruction;
        let cart = [...cartData];
        setCartData(cart);
        setModalVisible(false);
      }

    }


  };

  const handleVariantsButton = (data) => {

    setVariantsVisible(true);
    setVariants(data.variants);
    if (data.foodType) {
      setVariantFoodType(data.foodType)
    } else {
      setVariantFoodType('none')
    }
    if (data?.bestseller === 0) {
      setVariantIsBestseller(true)
    } else {
      setVariantIsBestseller(false)
    }

  }

  const handleviewcart = () => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'View Cart',
      'merchant_id': merchId,
      'shop_name': businessName
    });
    ReactGA.event({
      action: 'View Cart',
      label: 'Viewing cart items',
      dimension1: merchId,
      dimension2: businessName,
    });
    history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/cart` : '/cart')
  };

  const handleviewproductdetail = (data) => {
    // if (gridType === 1) {
    setProductId(data.id);
    let arr = []
    if (Array.isArray(data.image_url)) {
      setProductImage(data.image_url);
    } else {
      arr.push(data.image_url)
      setProductImage(arr)
    }
    console.log('datadatadatadatadata : ', data)
    if (data?.bestseller && data?.bestseller === 0) {
      setIfBestSeller(true);
    } else {
      setIfBestSeller(false);
    }

    setProductName(data.item_name);
    setProductPrice(data.price);
    if (data.brandName) {
      setPdBrand(data.brandName);
    } else {
      setPdBrand("");
    }

    if (data.discounted_rate === null || data.discounted_rate === undefined || data.discounted_rate === "") {
      setProductDiscPrice("");
      setPdOffer("")
    } else {
      setProductDiscPrice(data.discounted_rate);
      let offer_temp = '';
      offer_temp = 100 - (data.discounted_rate / data.price) * 100;
      setPdOffer(Math.round(offer_temp));
    }
    setProductCount(data.count);
    setProductDesc(data.desc);
    if (data.is_product_available === 1) {
      setProductAvailabilityData(data.avail_msg);
    } else {
      setProductAvailabilityData('');
    }

    console.log(data.desc);
    setProductDetailVisible(true);

    if (data.variants && data.variants.length !== 0) {
      setProductVariantExists(true)
      setVarDataFromPd(data)
      setPdVariantLength(data.variants.length)
    } else {
      setProductVariantExists(false)
    }
    // } else {
    //   const currentPath = window.location.pathname;
    //   const formattedString = data.item_name.replace(/ /g, '-');
    //   if (currentPath.includes('/cid=')) {
    //     const cidMatch = currentPath.match(/\/cid=([^/]+)/);
    //     history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/product/${data.id}/${formattedString}` : `/product/${data.id}/${formattedString}`, { returnValue: cidMatch[1] })
    //   } else {
    //     history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/product/${data.id}/${formattedString}` : `/product/${data.id}/${formattedString}`)
    //   }
    // }

  };


  const currency = (num) => {
    const hasFractionalPart = num % 1 !== 0;
    const fractionDigits = hasFractionalPart ? 1 : 0;
    const curr = new Intl.NumberFormat("en-IN", {
      style: "currency",
      currency: "INR",
      minimumFractionDigits: fractionDigits,
      maximumFractionDigits: fractionDigits
    })
      .format(num)
      .replace(/(\.|,)00$/g, "")
      .replace("₹", "₹ ");
    return curr;

  };

  const handlewhatsapp = () => {
    if (window.ReactNativeWebView && userApp != null) { // ensure window.ReactNativeWebView is there, otherwise, web app might crash if is not there
      window.ReactNativeWebView.postMessage(`https://api.whatsapp.com/send?phone=91${PhoneNumber}`)
    } else {
      window.open(
        `https://api.whatsapp.com/send?phone=919289454472&text=Website order for ${aliasname}`
      );
    }
  };

  const getS1CategoryProductsForGrid1 = async (category) => {
    setIsLoading(true);
    const data = {
      merchantID: merchId,
      category: category.category,
      categoryId: category.categoryId,
      isFromApp: false
    }
    try {
      const result = await ApiService.getS1CategoriesProducts(data);
      if (result?.data?.code == 0) {
        const newArrayToAdd = result.data.data;
        const filteredNewArray = newArrayToAdd.filter(
          (newItem) => !bestSeller.some((existingItem) => existingItem.id === newItem.id)
        );
        setBestSeller([...bestSeller, ...filteredNewArray]);
        let list = [];
        s1CategoryList.filter((dat) => dat.category === category.category)
          .map((d => {
            d.show = !d.show;
            d.apiCalled = true;
          }))
        list = [...s1CategoryList]
        setS1CategoryList(list);
      } else {
        console.log("Data fetch failed")
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setIsLoading(false);
    }
  }

  const openCategory = (category) => {
    let apiCalled = false;
    s1CategoryList.filter((dat) => dat.category === category.category)
      .map((d => {
        if (d.apiCalled === true) {
          d.show = !d.show
          apiCalled = true;
        }
      }))
    if (apiCalled) {
      let list = [...s1CategoryList];
      setS1CategoryList(list);
    } else {
      getS1CategoryProductsForGrid1(category);
    }
  }

  function scrollToElement(id) {
    const element = document.getElementById(id);
    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }

  function scrollToGridS1(id) {
    return new Promise((resolve) => {
      const element = document.getElementById(`grid-cat-${id}`);
      if (element) {
        const observer = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting) {
            observer.disconnect();
            resolve();
          }
        }, { threshold: 1.0 });

        observer.observe(element);
        element.scrollIntoView({ behavior: 'smooth', inline: 'center' });
      } else {
        resolve();
      }
    });

  }

  const gotoMenuItem = (index) => {
    scrollToElement(index);
    if (s1CategoryList[index].show === false) {
      openCategory(s1CategoryList[index]);
    }
    setMenuModalVisible(false);
  }

  function removeTrailingSlash(url) {
    console.log("UURL", url)
    console.log("Endswith", url.endsWith('/'))
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }

  const handleShare = async () => {
    // const uuidWithoutHyphens = productId.replace(/-/g, '');
    // const shortId = await getShortId(uuidWithoutHyphens); // converting long id to short id
    const formattedString = productName.replace(/[^\w\s]/g, '')?.replace(/\s+/g, '-');
    const domain = removeTrailingSlash(localStorage.getItem("webUrl"));
    console.log("withoutSla", domain)
    const shareUrl = domain + '/product/' + productId + '/' + formattedString; // creating link to share

    // Assuming you have an image URL
    console.log("URLIMAGE", productImage[0])
    console.log("shareUrl", shareUrl)
    if (productImage[0]) {
      const imageUrl = productImage[0];
      let response;
      try {
        response = await fetch(imageUrl, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            Origin: window.location.origin,
          },
        });
      } catch (error) {
        console.log(error);
        response = "error"
      }
      if (response !== 'error') {
        const blob = await response.blob();
        const file = new File([blob], 'image.jpg', { type: "image/jpeg" });
        console.log(file)
        if (navigator.share) {
          navigator.share({
            title: 'Hey, I found this product on ChattyBao',
            text: `Check out ${productName}`,
            url: shareUrl,
            files: [file]
          })
            .then(() => console.log('Successful share'))
            .catch((error) => console.error('Error sharing link:', error));
        } else {
          console.log('Web Share API not supported');
        }
      } else {
        if (navigator.share) {
          navigator.share({
            title: 'Hey, I found this product on ChattyBao',
            text: `Check out ${productName}`,
            url: shareUrl,
          })
            .then(() => console.log('Successful share'))
            .catch((error) => console.error('Error sharing link:', error));
        } else {
          console.log('Web Share API not supported');
        }
      }
    } else {
      if (navigator.share) {
        navigator.share({
          title: 'Hey, I found this product on ChattyBao',
          text: `Check out ${productName}`,
          url: shareUrl,
        })
          .then(() => console.log('Successful share'))
          .catch((error) => console.error('Error sharing link:', error));
      } else {
        console.log('Web Share API not supported');
      }
    }


  };

  const getMerchantDet = async (sessionId) => {
    const token = sessionId;
    var urlPath = window.location.pathname;
    var domainName = window.location.hostname;
    console.log('domainName on getMerchantDet : ', domainName);
    let merchantName = ''
    if (domainName !== 'devwebsite.chattybao.com' && domainName !== 'localhost' && domainName !== 'www.chattybao.com' && domainName !== 'chattybao.com') {
      merchantName = domainName
    } else {
      merchantName = urlPath.substring(urlPath.lastIndexOf("/") + 1);
    }

    const data = {
      merchantId: merchantName,
      domain: merchantName,
      ulatitude: '16.43',
      ulongitude: '74.59'
    }
    try {
      const result = await ApiService.getMerchantDataForCatalogue(data, token);
      if (result?.data?.code == 0) {
        console.log('result.data : ', JSON.stringify(result.data))
        localStorage.setItem("merchantdetails", JSON.stringify(result.data));
        setOfferBanner(result.data.merchantDetails.userOfferBanner);
      } else {
        console.log("Error")
      }
    } catch (error) {
      alert(error.message)
    } finally {

    }
  }

  const getChannelDetailsv3 = async (fromValue) => {
    setIsLoading(true);
    const token = localStorage.getItem('sessionId');
    const data = {
      merchantId: merchId,
    }
    await ApiService.getChannelDetailsv3(data, token)
      .then(async result => {
        setIsLoading(false);
        if (result?.data?.data?.is_checkout_order == 0) {
          if ((result?.data?.data?.delivery_mode == 0 && result.data.data.shipment_status === 5) || result.data.data.overall_status === 1 || result.data.data.PaymentDone === 1) {
            if (fromValue === "fromLogin") {
              getModifiedData(fromValue);
            }
            setShowOrderSummary(false);
            setOrderPlaced(false);
            getModifiedData();
          } else {
            var dateObj = new Date(result.data.data.order_created_date);
            function getOrdinalSuffix(day) {
              if (day > 3 && day < 21) return "th";
              switch (day % 10) {
                case 1:
                  return "st";
                case 2:
                  return "nd";
                case 3:
                  return "rd";
                default:
                  return "th";
              }
            }
            localStorage.setItem('summaryData', JSON.stringify(result.data))
            localStorage.removeItem("modifiedData")
            localStorage.removeItem("cartData")
            // setShowOrderSummary(true);
            setOrderPlaced(true);
            dispatch(setState({ key: "totalCount", value: 0 }));
            // Format the date and time
            var formattedDate =
              dateObj.getDate() +
              getOrdinalSuffix(dateObj.getDate()) +
              " " +
              dateObj.toLocaleString("default", { month: "long" }) +
              ", " +
              dateObj.getFullYear() +
              " | " +
              dateObj.toLocaleString("en-US", {
                hour: "numeric",
                minute: "numeric",
                hour12: true
              });
            setOrderStatus({
              orderId: result.data.data.order_id,
              stageStatus: result.data.data.stage_status,
              deliveryMode: result.data.data.delivery_mode,
              shipmentStatus: result.data.data.shipment_status,
              orderDate: formattedDate
            });
            let sav = Number(result.data.data.full_amount) - Number(result.data.data.order_total_amount)
            let logiData = {
              deliveryCharge: (result.data.data.total_user_logistic_charge !== "null" && result.data.data.total_user_logistic_charge !== "undefined") ? result.data.data.total_user_logistic_charge : 0,
              govtTax: result.data.data.govt_gst,
              payAmount: result.data.data.amt_to_pay,
              cashback: result.data.data.user_offer_amt,
              offerText: result.data.data.offerText,
              mrpTotal: result.data.data.full_amount,
              itemTotal: result.data.data.order_total_amount,
              savings: sav,
              orderStatusText: result.data.data.orderMsg,
              orderStatusTitle: result.data.data.orderMsgTitle,
              customProduct: result.data.data.extra_cart_items,
            }
            setLogisticData(logiData);
            setCartDat(result.data.data.order_summary);
            setShowDownloadBanner(false);
            dispatch(setState({ key: "summaryData", value: { data: result.data.data, destinationLatlng: result.data.destinationLatlng, originLatlng: result.data.originLatlng } }));
          }
        } else if (result.data.message === 'Invalid Token') {
          localStorage.removeItem("User");
          localStorage.removeItem("sessionId");
        } else {
          if (fromValue === "fromLogin") {
            getModifiedData(fromValue);
          }
          setShowOrderSummary(false);
          setOrderPlaced(false);
          getModifiedData();
        }
        if (result?.data?.data?.loyaltyEnabled) {
          const merDetails = JSON.parse(localStorage.getItem("merchantdetails"));
          merDetails.merchantDetails.loyaltyPoints = result?.data?.data?.totalLoyaltyPoints;
          localStorage.setItem("merchantdetails", JSON.stringify(merDetails));
        }
      })
  }

  const giveToastMessage = () => {
    toast("Cannot add to cart when an order is in progress!", { autoClose: 500 })
  }

  const getMerchUserChannel = async (value) => {
    if (value === "fromLogin") {
      getChannelDetailsv3(value);
    } else {
      getChannelDetailsv3();
    }
  }
  const getModifiedData = (value) => {
    const cart = (JSON.parse(localStorage.getItem("cartData")));
    let sPrice;
    // Calculations for cart
    if (cart) {
      let filterData = cart.filter((data) => data.variants === undefined || data.variants === null || data.variants === "" || data.variants.length === 0)
      let varPrice1 = [];
      let countprice1 = filterData.map((data) => (data.count > 0 ? ((data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined) ? data.count * parseFloat(data.discounted_rate) : data.count * parseFloat(data.price)) : 0));
      console.log("countprice", countprice1);
      for (let i = 0; i < cart.length; i++) {
        if (cart[i].variants && cart[i].variants.length !== 0) {
          for (let j = 0; j < cart[i].variants.length; j++) {
            if (cart[i].variants[j].count > 0) {
              if (cart[i].variants[j].variantDisPrice !== undefined && cart[i].variants[j].variantDisPrice !== null && cart[i].variants[j].variantDisPrice !== '') {
                varPrice1.push(cart[i].variants[j].count * parseFloat(cart[i].variants[j].variantDisPrice))
              } else {
                varPrice1.push(cart[i].variants[j].count * parseFloat(cart[i].variants[j].variantPrice))
              }
            }
          }
        }

      }
      var otherPrice1 = countprice1.reduce((partialSum, a) => partialSum + a, 0);
      var ttPrice1 = varPrice1.reduce((partialSum, a) => partialSum + a, 0);
      sPrice = otherPrice1 + ttPrice1;
    }

    if (localStorage.getItem("extraInstructions")) {
      setCustomInstructions(JSON.parse(localStorage.getItem("extraInstructions")));
    }
    if (localStorage.getItem("fromContact") === null) {
      if (value === "fromLogin") {
        console.log("THISSUM", sPrice)
        handleviewcart();
      }
    } else {
      if (localStorage.getItem("fromContact") === '1') {
        localStorage.removeItem("fromContact")
      }
    }

    if (localStorage.getItem("cartFromContact") === '1') {
      if (localStorage.getItem("User")) {
        handleviewcart();
      } else {
        // setLoginPage(true);
        dispatch(setState({ key: "loginPage", value: true }));
        dispatch(setState({ key: "iframeLoader", value: true }));
        // setIframeLoader(true);
      }
      localStorage.removeItem("cartFromContact");
    }
  }

  const getS1CategoryProducts = async (categoryName, categoryId, s1Index) => {
    setCatalogLoader(true);
    const data = {
      merchantID: merchId,
      category: categoryName,
      categoryId: categoryId,
      isFromApp: false
    }
    try {
      const result = await ApiService.getS1CategoriesProducts(data);
      if (result?.data?.code == 0) {
        console.log("catalogs1", result)
        setBestSeller(result.data.data)
        if (categoryBarRef.current) {
          if (s1Index !== -1) {
            await scrollToGridS1(s1Index)
          }
          // setTimeout(async () => {
          // }, 500)
          setTimeout(() => {
            categoryBarRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
          }, 100)
        }
      } else {
        console.log("Data fetch failed")
        setBestSeller([]);
      }
    } catch (error) {
      console.log(error.message)
    } finally {
      setCatalogLoader(false);
    }
  }

  const handleS1CategoryClick = async (categoryName, categoryId, id) => {
    setMenuModalVisible(false);
    if (id || id === 0) {
      await scrollToGridS1(id);
    }
    setSelectedS1({ category: categoryName, categoryId: categoryId });
    getS1CategoryProducts(categoryName, categoryId)
    // if (s1CategoryList[0].id === categoryId) {
    //   window.location = window.location.href.split('#')[0];
    // } else {
    const currentPath = window.location.pathname;
    // const uuidWithoutHyphens = categoryId.replace(/-/g, '');
    // const shortId = await getShortId(uuidWithoutHyphens);
    if (!currentPath.includes('/cid=')) {
      history.replace(`${localStorage.getItem("fullurl")}/cid=${categoryId}`)
      // window.location = window.location + `#${categoryId}`;
    } else {
      const updatedPath = currentPath.replace(/\/cid=[^/]+$/, `/cid=${categoryId}`);
      history.replace(updatedPath)
      // window.location = window.location.href.split('#')[0] + `#${categoryId}`;
    }
    // }
  }

  const scrollToS1Category = () => {
    if (gridType !== 1) {
      if (s1CategoryRef.current) {
        s1CategoryRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
      }
    } else {
      if (s1CategoryRef.current) {
        s1CategoryRef.current.scrollIntoView({ top: 0, behavior: 'smooth' });
      }
    }

  };

  const handleLoad = () => {
    // setIframeLoader(false);
    dispatch(setState({ key: "iframeLoader", value: false }))
  }

  // const testBtn = () => {
  //   localStorage.setItem("loyaltyPoints", 90)
  // }

  return (
    <div className={`catalog-container ${searchInput !== '' ? 'searched-catalog-container' : ''}`}>
      {/* <button onClick={testBtn}>click here</button> */}
      <ToastContainer className='toast-container' hideProgressBar={true} theme="dark" position="bottom-center" toastStyle={toastStyle} />
      {isLoading && (
        <LoadingSpinner />
      )}
      {/* Menu open */}
      {menuModalVisible ? (
        <Dialog classes={{ paper: classes.menuDialog }} onClose={() => setMenuModalVisible(!menuModalVisible)} open={menuModalVisible}>
          <DialogContent className="dialog_instruction" style={{}}>
            <div className="menu-dialog-container">
              <p className="preferred_heading">Menu</p>
              {s1CategoryList.map((d, index) => {
                return (
                  <div className="menu-open-container" hidden={d.count === 0} key={index} onClick={gridType === 1 ? () => gotoMenuItem(index) : () => handleS1CategoryClick(d.category, d.id, index)}>
                    <p className="menu_category">{d.category}</p>
                    <p className='menu_category menu-category-count'>{d.count}</p>
                  </div>
                )
              })}
            </div>
          </DialogContent>
        </Dialog>
      ) : (
        <div className={totalCount > 0 || orderPlaced ? 'menu_icon2' : "menu_icon"}>
          <img src={menu_icon} onClick={() => setMenuModalVisible(true)} className="menu-icon-img" alt="NA" />
        </div>
      )}

      {/* Offer banner and delivery banner card */}
      {/* console.log('bannerImages') */}
      {searchInput === '' && (
        <div className="banner-container">
          <Slider {...carousel}>
            {bannerImages.filter(dat => dat)
              .map((data, index) => (
                <div>
                  <div>
                    <img
                      onClick={() => window.open(userCashbackTermsPage)}
                      src={data}
                      alt="img"
                      className="banner-images"
                    // height={363}
                    />
                  </div>
                </div>
              ))}
          </Slider>
        </div>
      )}

      {/* top tabs */}
      {searchInput === '' && (
        <div className="top-buttons-container">
          <div className={(whatsappOpen || openPhone) ? "left-btn-inactive" : "left-btn-active"}>Order Online</div>
          <div onClick={() => dispatch(setState({ key: "whatsappOpen", value: true }))} className={whatsappOpen ? "center-btn-active" : "center-btn-inactive"}>
            <p className="order-on-whatsapp-text">Order on</p>
            <img src={whatsappOpen ? whatsappWhiteIcon : whatsappGreenIcon} alt="w/a" className="btn-icons" />
          </div>
          <div onClick={() => dispatch(setState({ key: "openPhone", value: true }))} className={openPhone ? "right-btn-active" : "right-btn-inactive"}>
            <p className="order-on-call-text">Order on</p>
            <img src={openPhone ? callWhiteIcon : callGreenIcon} alt="call" className="btn-icons" />
          </div>
        </div>
      )}

      {/* Products Tag */}
      {/* {searchInput === '' && (
        <div ref={s1CategoryRef} className="products-header-container">
          <img src={flourishLeft} className="flourish-icon" alt="Flourish" />
          <p className="products-header-text">Products</p>
          <img src={flourishRight} className="flourish-icon" alt="Flourish" />
        </div>
      )} */}

      {modificationData.length === 0 && (
        <div className="no-data-text-container">
          <Typography className="search_empty_text" variant="caption" color="text.secondary">
            Did not find what you are looking for?
          </Typography>
          <Typography onClick={handlewhatsapp} className="chat_enquire_text" variant="caption" noWrap color="text.secondary">
            Chat & Enquire
          </Typography>
        </div>
      )}


      {gridType !== 1 && searchInput === '' && (
        <div ref={categoryBarRef} className="category-bar">
          {s1CategoryList.length !== 0 && s1CategoryList.map((data, index) => (
            <div id={`grid-cat-${index}`} onClick={selectedS1.category === data.category ? null : () => handleS1CategoryClick(data.category, data.id)} className={selectedS1.category === data.category ? 's1category-name-selected' : "s1category-name"}>{data.category}</div>
          ))}
        </div>
      )}


      {gridType !== 1 && searchInput === '' && (
        <div>
          {catalogLoader ? (
            <div className="catalog-loader-container">
              <ClipLoader size={30} color="#125d8d" loading={true} />
            </div>
          ) : (
            <div className="grid">

              {modificationData && modificationData
                .map((data, index) => {
                  let discountExists = "";
                  let variantsExists = false;
                  let variantLength = ''
                  discountExists = "none";
                  let offer = '';
                  if (data.variants && data.variants.length !== 0) {
                    variantsExists = true;
                    if (data.variants[0].variantDisPrice) {
                      discountExists = "inline";
                      offer = 100 - (data.variants[0].variantDisPrice / data.variants[0].variantPrice) * 100;
                      offer = Math.round(offer)
                    }
                    variantLength = data.variants.length;
                  } else {
                    if (data.discounted_rate !== null && data.discounted_rate !== "" && data.discounted_rate !== undefined && data.discounted_rate !== data.price) {
                      discountExists = "inline";
                      offer = 100 - (data.discounted_rate / data.price) * 100;
                      offer = Math.round(offer)
                    }
                  }

                  return (
                    <>
                      <Card className="box" key={data.id}>

                        <div className="relative-position">
                          <div hidden={discountExists === 'none'} className="offer-tag-container">
                            <p className="offer_text">{offer}% {"\n"} OFF </p>
                            <img src={discount_tag} className="discount-tag" alt='discount' />
                          </div>
                          <div className="best-seller-tag-container">
                            {data?.bestseller === 0 && data?.bestseller !== undefined && data?.bestseller !== null && data?.bestseller !== '' ? (
                              <img src={bestSeller_tag} className="best-seller-tag-img" alt='bestseller-tag' />
                            ) : ''}

                          </div>
                          {data.image_url[0] ? (
                            <LazyLoadImage component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} className='gridImage' alt="NA" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                          ) : (
                            <div className="no-image-container-grid">
                              <LazyLoadImage className="no-image-grid" component="img" src={imageNotAvailable} onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                            </div>
                          )}
                        </div>

                        {/* product list display */}
                        <div className="product-bottom-container">
                          <div className="brand-name-container">
                            {data.brandName ? (
                              <p className="brand_name">{data.brandName}</p>
                            ) : ''}
                            <Typography className={`item_name ${data.brandName ? '' : 'item-name-optional2'}`} color="text.secondary">
                              {data.item_name}
                            </Typography>
                          </div>
                          <div className="bottomline_container">
                            <div>
                              <div className="price-container">
                                <div className={`${discountExists === 'inline' ? '' : 'product_offer_price_nodiscount1'}`}>

                                  <Typography
                                    variant="subtile1"
                                    className={` ${discountExists === 'inline' ? 'normal_item cut-out-price' : 'product_offer_price_nodiscount single-price'}`}
                                  >
                                    {data.price && currency(data.price)}
                                  </Typography>
                                </div>
                                {discountExists === 'inline' ? (
                                  <div>
                                    <Typography
                                      variant="subtile1"
                                      color="text.secondary"
                                      className="product_offer_price discount-price-grid"
                                    >
                                      {currency(data.discounted_rate * 1)}
                                    </Typography>

                                  </div>
                                ) : ''}
                              </div>
                            </div>
                            <span className="grid-add-btn-container">
                              {data.count > 0 ? (
                                <div className="grid-add-box-filled">
                                  <p className="plus_minus_text" onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                  <p className="product_count">{data.count}</p>
                                  <p className="plus_minus_text" onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>
                                </div>

                              ) : (
                                <>
                                  {data.status === 0 ? (
                                    <div className="add-btn-grid" onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}>
                                      {variantsExists ? (
                                        <p className="options_text_grid">{`${variantLength} Options`}</p>
                                      ) : (
                                        <p className="add_text_grid">ADD</p>
                                      )}
                                    </div>
                                  ) : (
                                    <div className="not-available-text-container">
                                      <p className="not-available-text-grid">Not Available</p>
                                    </div>
                                  )}
                                </>

                              )}
                            </span>

                          </div>
                        </div>


                      </Card>
                    </>
                  )
                })}
            </div>
          )}
        </div>

      )}

      {/* For search */}

      {searchInput !== '' && (
        <div>
          {catalogLoader ? (
            <div className="catalog-loader-container">
              <ClipLoader size={30} color="#125d8d" loading={true} />
            </div>
          ) : (
            <div>
              {(gridType !== 1) ? (
                <div className="grid">

                  {/* 2 x 2 for s1category */}

                  {modificationData.length !== 0 && modificationData
                    .map((data, index) => {
                      let discountExists = "";
                      let variantsExists = false;
                      let variantLength = ''
                      discountExists = "none";
                      let offer = '';
                      if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                        variantsExists = true;
                        if (data.variants[0].variantDisPrice !== null && data.variants[0].variantDisPrice !== "" && data.variants[0].variantDisPrice !== undefined) {
                          discountExists = "inline";
                          offer = 100 - (data.variants[0].variantDisPrice / data.variants[0].variantPrice) * 100;
                          offer = Math.round(offer)
                        }
                        variantLength = data.variants.length;
                      } else {
                        if (data.discounted_rate && data.discounted_rate !== data.price) {
                          discountExists = "inline";
                          offer = 100 - (data.discounted_rate / data.price) * 100;
                          offer = Math.round(offer)
                        }
                      }
                      return (
                        <>
                          <Card className="box" key={data.id}>

                            <div className="relative-position">
                              <div hidden={discountExists === 'none'} className="offer-tag-container">
                                <p className="offer_text">{offer}% {"\n"} OFF </p>
                                <img src={discount_tag} style={{ width: "40px" }} alt='discount' />
                              </div>
                              <div className="best-seller-tag-container">
                                {data?.bestseller === 0 && data?.bestseller !== undefined && data?.bestseller !== null && data?.bestseller !== '' ? (
                                  <img src={bestSeller_tag} className="best-seller-tag-img" alt='bestseller-tag' />
                                ) : ''}

                              </div>
                              {data.image_url[0] ? (
                                <LazyLoadImage component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} className='gridImage' alt="NA" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                              ) : (
                                <div className="no-image-container-grid">
                                  <LazyLoadImage className="no-image-grid" component="img" src={imageNotAvailable} onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                                </div>
                              )}
                            </div>

                            <div className="product-bottom-container">
                              <div className="brand-name-container">
                                {data.brandName ? (
                                  <p className="brand_name">{data.brandName}</p>
                                ) : ''}
                                <Typography color="text.secondary" className={`item_name ${data.brandName ? '' : 'item-name-optional2'}`}>
                                  {data.item_name}
                                </Typography>
                              </div>
                              <div className="bottomline_container">
                                <div>
                                  <div className="price-container">
                                    <div className={`${discountExists === 'inline' ? '' : 'product_offer_price_nodiscount1'}`}>

                                      <Typography
                                        variant="subtile1"
                                        className={`${discountExists === 'inline' ? 'normal_item cut-out-price' : 'product_offer_price_nodiscount single-price'}`}
                                      >
                                        {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                                      </Typography>
                                    </div>
                                    {discountExists === 'inline' ? (
                                      <div>
                                        <Typography
                                          variant="subtile1"
                                          color="text.secondary"
                                          className="product_offer_price discount-price-grid"
                                        >
                                          {currency(data.discounted_rate)}
                                        </Typography>

                                      </div>
                                    ) : ''}
                                  </div>
                                </div>
                                <span className="grid-add-btn-container">
                                  {data.count > 0 ? (
                                    <div className="grid-add-box-filled">
                                      <p className="plus_minus_text" onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                      <p className="product_count">{data.count}</p>
                                      <p className="plus_minus_text" onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>
                                    </div>

                                  ) : (
                                    <div className="add-btn-grid" onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}>
                                      {variantsExists ? (
                                        <p className="options_text_grid">{`${variantLength} Options`}</p>
                                      ) : (
                                        <p className="add_text_grid">ADD</p>
                                      )}

                                    </div>



                                  )}
                                </span>

                              </div>
                            </div>


                          </Card>
                        </>
                      )
                    })}
                </div>
              ) : (
                <div className="grid2">

                  {/* 1 x n for s1category search results */}

                  {modificationData.length !== 0 && modificationData
                    .map((data, index) => {
                      let discountExists = "";
                      let textDecoration = "";
                      let fdType = '';
                      let foodTypeIconShow = false;
                      let variantsExists = false;
                      let variantLength = 0;
                      discountExists = "none";
                      textDecoration = "none";
                      let offer = '';
                      console.log("discount value", data.discounted_rate);
                      if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                        foodTypeIconShow = true;
                        if (data.foodType === 'Non-Veg') {
                          fdType = non_veg_icon;
                        } else if (data.foodType === 'Egg') {
                          fdType = egg_icon;
                        } else {
                          fdType = veg_icon;
                        }
                      }
                      if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                        variantsExists = true;
                        variantLength = data.variants.length;
                      }
                      if (data.discounted_rate && data.discounted_rate !== data.price) {
                        discountExists = "inline";
                        textDecoration = "line-through";
                        offer = 100 - (data.discounted_rate / data.price) * 100;
                        offer = Math.round(offer)
                      }

                      let instructionFromHomePage = true;

                      let tags = false;
                      if (discountExists === "inline" || data.foodType || (data?.bestseller === 0)) {
                        tags = true;
                      } else {
                        tags = false;
                      }

                      return (
                        <>
                          <Card className="maincards" key={data.id}>
                            <CardContent className="main_image_container">
                              <div className="tags_container">
                                <img src={fdType} hidden={!foodTypeIconShow} className="fd-type-icon" alt="food-type" />
                                <div className="relative-position" hidden={discountExists === 'none'}>
                                  <div className="offer_text2">
                                    <p className="offer_text2" style={{ color: "white" }}>{offer}% OFF</p>
                                  </div>
                                  <img className="discount-tag-non-grid" src={discount_tag_hor} alt="tag" />
                                </div>
                                {(data?.bestseller === 0) ? (
                                  <img src={bestSeller_tag} className="bestseller-tag-non-grid" alt="tag" />
                                ) : ''}

                              </div>
                              <Typography className="product_name" color="text.secondary" style={{ lineHeight: "1.2", wordBreak: 'break-word' }}>
                                {data.item_name}
                              </Typography>

                              <Typography
                                variant="subtile1"
                                className={`${discountExists === 'inline' ? 'normal_item2' : 'product_normal_price2'}`}
                                style={{ textAlign: "left", color: "black", textDecoration: `${textDecoration}` }}
                              >
                                {data.price && currency(data.price)}
                              </Typography>

                              {discountExists !== 'none' ? (
                                <Typography
                                  variant="subtile1"
                                  color="text.secondary"
                                  className="product_offer_price2"
                                >
                                  {data.discounted_rate && currency(data.discounted_rate)}
                                </Typography>
                              ) : ''}

                              {/* <Divider className="dot_divider" /> */}

                              <Typography className="maincards_typodescription2" variant="h6" color="text.secondary" style={{ paddingTop: "9px" }}>
                                {data.desc ? data.desc : " "}
                              </Typography>
                            </CardContent>

                            <CardContent className={data.image_url[0] ? "main_image_container" : (tags ? "no-image-btns-container-tags" : "no-image-btns-container")}>
                              {data.image_url[0] && (
                                <span>
                                  <LazyLoadImage className="cardimgs" component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} alt="Image not available" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                                </span>
                              )}

                              {data.is_product_available === 1 ? (
                                <CardActions disableSpacing className="product_availability_container">
                                  <span className="product_availability_btn" style={{ paddingBottom: "2px", position: "relative" }}>
                                    <div style={{ display: 'flex', justifyContent: 'center', width: '100px', alignItems: 'center', border: '1px solid #D9D9D9', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                      <p className="product_availability_text">{data.avail_msg}</p>
                                    </div>
                                  </span>
                                </CardActions>

                              ) : (
                                <span>
                                  {data.count > 0 ? (
                                    <>
                                      <CardActions disableSpacing className="main_add_sub_container_single">
                                        <span
                                          className="maincard_button"
                                          style={{ paddingBottom: "2px", position: "relative" }}
                                        >
                                          <div className="grid-add-box-filled-options">
                                            <p className="plus_minus_text_non_grid" onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                            <p className="product_count2">{data.count}</p>
                                            <p className="plus_minus_text_non_grid" onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>

                                          </div>
                                        </span>
                                      </CardActions>
                                    </>
                                  ) : (
                                    <CardActions disableSpacing className="main_add_sub_container_single">
                                      <span
                                        className="maincard_button"
                                        onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}
                                      >
                                        <div className="add-options-btn-non-grid">
                                          {variantsExists ? (
                                            <p className="options_text">{`${variantLength} Options`}</p>
                                          ) : (
                                            <p className="add_text">ADD</p>
                                          )}
                                        </div>
                                      </span>
                                    </CardActions>
                                  )}
                                  <div class="special_instruction">
                                    {data.instruction == "" ? (
                                      <div className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                        <div className="plus_text">+ </div>
                                        <div className="specialInstructionText">Special Instructions</div>
                                      </div>
                                    ) : (
                                      <div className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                        <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                        <p className="specialEditText">{data.instruction} </p>
                                      </div>
                                    )}
                                  </div>
                                </span>
                              )}


                            </CardContent>
                          </Card>

                        </>
                      )
                    })}
                </div>
              )}
            </div>
          )}
        </div>

      )}

      {catalogLoader && gridType === 1 && searchInput === '' &&
        <div className="catalog-loader-container">
          <ClipLoader size={30} color="#125d8d" loading={true} />
        </div>}

      {/* For 1 x n layout */}

      {s1CategoryList.length !== 0 && s1CategoryList.length > 0 && gridType === 1 && searchInput === '' ? (
        s1CategoryList.filter(catData => catData.count !== 0)
          .map((cat, index) => {
            return (
              <div key={index}>
                {searchInput === '' ? (
                  <>
                    <span onClick={() => openCategory(cat)} className="non-grid-s1-category-container">
                      <div className="non-grid-s-name-container">
                        <p id={index} className="s1cat_name">{cat.category}</p>
                        <p className="s1cat_count">{`(${cat.count})`}</p>
                      </div>

                      <img src={cat.show ? up_arrow : down_arrow} className="category-arrow-icon" alt="NA" />

                    </span>
                  </>
                ) : ''}

                <div hidden={!cat.show}>
                  <div className="grid2">
                    {modificationData.length !== 0 && modificationData.filter((dat) => dat.s1category === cat.category)
                      .map((data, index) => {
                        let discountExists = "";
                        let textDecoration = "";
                        let fdType = '';
                        let foodTypeIconShow = false;
                        let variantsExists = false;
                        let variantLength = 0;
                        discountExists = "none";
                        textDecoration = "none";
                        let offer = '';
                        console.log("discount value", data.discounted_rate);
                        if (data.foodType !== null && data.foodType !== undefined && data.foodType !== "") {
                          foodTypeIconShow = true;
                          if (data.foodType === 'Non-Veg') {
                            fdType = non_veg_icon;
                          } else if (data.foodType === 'Egg') {
                            fdType = egg_icon;
                          } else {
                            fdType = veg_icon;
                          }
                        }
                        if (data.variants !== null && data.variants !== undefined && data.variants !== "" && data.variants.length !== 0) {
                          variantsExists = true;
                          variantLength = data.variants.length;
                        }
                        if (data.discounted_rate && data.discounted_rate !== data.price) {
                          discountExists = "inline";
                          textDecoration = "line-through";
                          offer = 100 - (data.discounted_rate / data.price) * 100;
                          offer = Math.round(offer)
                        }

                        let instructionFromHomePage = true;

                        let tags = false;
                        if (discountExists === 'inline' || data.foodType || (data?.bestseller === 0)) {
                          tags = true;
                        } else {
                          tags = false;
                        }

                        return (
                          <>
                            <Card className="maincards" key={data.id}>
                              <CardContent className="main_image_container">
                                <div className="tags_container">
                                  <img src={fdType} hidden={!foodTypeIconShow} className="fd-type-icon" alt="food-type" />
                                  <div style={{ position: 'relative', display: `${discountExists === 'none' ? 'none' : 'block'}` }}>
                                    <div className="offer_text2">
                                      <p className="offer_text2" style={{ color: "white" }}>{offer}% OFF</p>
                                    </div>
                                    <img src={discount_tag_hor} className="discount-tag-non-grid" alt="tag" />
                                  </div>
                                  {(data?.bestseller === 0) ? (
                                    <img src={bestSeller_tag} className="bestseller-tag-non-grid" alt="tag" />
                                  ) : ''}

                                </div>
                                <Typography className="product_name" color="text.secondary" style={{ lineHeight: "1.2", wordBreak: 'break-word' }}>
                                  {data.item_name}
                                </Typography>

                                <Typography
                                  variant="subtile1"
                                  className={`${discountExists === 'inline' ? 'normal_item2' : 'product_normal_price2'}`}
                                  style={{ textAlign: "left", color: "black", textDecoration: `${textDecoration}` }}
                                >
                                  {data.price && data.price ? currency(data.price * 1) : "₹ 5"}
                                </Typography>
                                {discountExists !== 'none' ? (
                                  <Typography
                                    variant="subtile1"
                                    color="text.secondary"
                                    className="product_offer_price2"
                                  >
                                    {data.discounted_rate && currency(data.discounted_rate)}
                                  </Typography>
                                ) : ''}

                                {/* <Typography ref={descriptionRef} className="maincards_typodescription2" variant="h6" color="text.secondary" style={{ paddingTop: "9px" }}>
                                    {data.desc ? data.desc : " "}
                                  </Typography> */}
                                {data.desc && (
                                  <TextOverFlow text={data.desc} />
                                )}
                              </CardContent>

                              <CardContent className={data.image_url[0] ? "main_image_container" : (tags ? "no-image-btns-container-tags" : "no-image-btns-container")}>
                                {data.image_url[0] && (
                                  <span>
                                    <LazyLoadImage className="cardimgs" component="img" src={Array.isArray(data.image_url) ? data.image_url[0] : data.image_url} alt="Image not available" onClick={data.status === 0 ? () => handleviewproductdetail(data) : null} />
                                  </span>
                                )}

                                {data.is_product_available === 1 ? (
                                  <CardActions disableSpacing className={data.image_url[0] ? "product_availability_container" : "product_availability_container_no_image"}>
                                    <span className="product_availability_btn" style={{ paddingBottom: "2px", position: "relative" }}>
                                      <div style={{ display: 'flex', justifyContent: 'center', width: '100px', alignItems: 'center', border: '1px solid #D9D9D9', paddingTop: '2px', borderRadius: '5px', backgroundColor: 'white' }}>
                                        <p className="product_availability_text">{data.avail_msg}</p>
                                      </div>
                                    </span>
                                  </CardActions>

                                ) : (
                                  <span>
                                    {data.count > 0 ? (
                                      <>
                                        <CardActions disableSpacing className="main_add_sub_container_single">
                                          <span
                                            className="maincard_button"
                                            style={{ paddingBottom: "2px", position: "relative" }}
                                          >
                                            <div className="grid-add-box-filled-options">
                                              <p className="plus_minus_text_non_grid" onClick={variantsExists ? () => handleVariantsButton(data) : () => decreaseCount(data.count, data.id, "fromHome")}>-</p>
                                              <p className="product_count2">{data.count}</p>
                                              <p className="plus_minus_text_non_grid" onClick={variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome")}>+</p>

                                            </div>
                                          </span>
                                        </CardActions>
                                      </>
                                    ) : (
                                      <CardActions disableSpacing className="main_add_sub_container_single">
                                        {data.status === 0 ? (
                                          <span
                                            className="maincard_button"
                                            style={{
                                            }}
                                            onClick={orderPlaced ? giveToastMessage : (variantsExists ? () => handleVariantsButton(data) : () => addCount(data.count, data.id, "fromHome"))}
                                          >
                                            <div className="add-options-btn-non-grid">
                                              {variantsExists ? (
                                                <p className="options_text">{`${variantLength} Options`}</p>
                                              ) : (
                                                <p className="add_text">ADD</p>
                                              )}
                                            </div>
                                          </span>
                                        ) : (
                                          <span
                                            className="maincard_button"
                                          >
                                            <div style={{ width: '120px', backgroundColor: 'grey', borderRadius: '5px', height: '28px' }}>
                                              <p className="unavailable_text">Not Available</p>
                                            </div>
                                          </span>
                                        )}

                                      </CardActions>
                                    )}
                                    {data.status === 0 ? (
                                      <div class="special_instruction">
                                        {data.instruction == "" ? (
                                          <a className="maincard_instruction1" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                            <div className="plus_text">+ </div>
                                            <div className="specialInstructionText">Special Instructions</div>
                                          </a>
                                        ) : (
                                          <a className="edit_instruction" onClick={() => modalopen(data.id, data.instruction, instructionFromHomePage)}>
                                            <img src={edit_icon} alt="icon" style={{ width: '14px', height: '18px' }}></img>
                                            <p className="specialEditText">{data.instruction} </p>
                                          </a>
                                        )}
                                      </div>
                                    ) : (
                                      <div class="special_instruction" />
                                    )}
                                  </span>
                                )}


                              </CardContent>
                            </Card>

                          </>
                        )
                      })}
                  </div>
                </div>

                {searchInput === '' || searchInput.toLowerCase() === cat.category.toLowerCase() ? (
                  <Divider className="dividers" />
                ) : ''}

              </div>
            )
          })
      ) : ''}

      {modificationData.length === 0 && searchInput === '' && (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            color: "blue",
            fontWeight: "bold",
            flexDirection: "column",
            border: "2px solid white",
            alignItems: "center",
            borderRadius: "5px",
          }}
        >
          <Typography variant="caption" color="text.secondary" style={{ color: "black", textAlign: "center" }}>
            Business has not added products. You may chat/call and inquire
          </Typography>
        </div>
      )}



      {modificationData.length !== 0 && (
        <div
          style={{
            display: "flex",
            textAlign: "center",
            color: "blue",
            fontWeight: "bold",
            flexDirection: "column",
            border: "2px solid white",
            alignItems: "center",
            borderRadius: "5px",
            marginTop: '30px',
          }}
        >
          <Typography className="search_empty_text" variant="caption" color="text.secondary">
            Looking for something else ?
          </Typography>
          <Typography onClick={() => dispatch(setState({ key: "whatsappOpen", value: true }))} className="chat_enquire_text pointer" variant="caption" noWrap color="text.secondary">
            You may Chat & Enquire
          </Typography>
        </div>
      )}

      {isFruitsAndVegMerchant === "GROCERY" && (
        <>
          <Divider className="dividers" style={{ marginBottom: '20px' }} />
          <Typography className="disclaimer" variant="caption" color="text.secondary" style={{ paddingBottom: '40px' }}>
            <div className="disclaimer">Disclaimer:</div>
            <p className="disclaimer">All images are for representation purpose only. While every effort is made to provide up to date product information, it is recommended to read product labels, warnings, and directions
              before using or consuming a product. For other products or any additional information, please contact the seller over chat or call. In case you wish to contact the manufacturer, the
              address mentioned on the product label should be used.</p>
          </Typography>
        </>
      )}

      <br />

      <div className="footer-text-container">
        <p onClick={() => window.open('https://chattybao.com')} className="footer-text-chattybao">Powered by ChattyBao Technologies Private Limited</p>
        <div className="">
          <span>
            <span onClick={() => window.open('https://chattybao.com/user-agreement')} className="footer-text-chattybao">Terms & Conditions</span>
            <span className="footer-text-chattybao"> | </span>
            <span onClick={() => window.open('https://chattybao.com/privacy-policy')} className="footer-text-chattybao">Privacy Policy</span>
            <span className="footer-text-chattybao"> | </span>
            <span onClick={() => window.open('https://chattybao.com/CancellationRefundsReturnExchange')} className="footer-text-chattybao">Cancellations & Refunds</span>
          </span>
          {/* <p className="footer-text-chattybao">||</p>
          <p className="footer-text-chattybao">Powered by ChattyBao Technologies Private Limited</p>
          <p className="footer-text-chattybao">||</p>
          <p className="footer-text-chattybao">Powered by ChattyBao Technologies Private Limited</p> */}
        </div>
      </div>

      {/*   Special Instructions */}
      <Dialog open={modalVisible} PaperProps={{ sx: { width: "70%", maxWidth: '307px' } }}>
        <div className="dialog_instruction">
          Special Instructions
          <CloseIcon
            className="close_btn"
            onClick={() => {
              setModalVisible(!modalVisible);
            }}
          />
        </div>
        <DialogContent className="dialogBox">
          <div
            style={{
              color: "blue",
              fontWeight: "bold",
              border: "2px solid white",
              borderRadius: "5px",
              margin: "4px 15px 15px 15px",
              width: "100%",
            }}
          >
            <TextField
              sx={{
                "& .MuiOutlinedInput-root": {
                  "& > fieldset": { borderColor: "#0375B0" },
                },
              }}
              id="outlined-basic"
              className="dialog_instruction_inputs"
              label="Max 30 characters"
              variant="outlined"
              value={instruction}
              multiline
              maxRows={2}
              onChange={(e) => {
                let input = e.target;
                if (input.value.includes('  ')) {
                  input.value = input.value.replace(/\s+/g, ' ');
                }
                if (input.value.charAt(0) === ' ') {
                  input.value = input.value.substring(1);
                }
                setInstruction(input.value);
              }}
              onInput={(e) => {
                e.target.value = e.target.value.toString().slice(0, 30);
              }}
            />
          </div>
        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0px 0px 16px 0px" }}>
          <button className="dialog_instruction_buttons" onClick={saveInstruction}>
            Save
          </button>
        </DialogActions>
      </Dialog>

      {/* View Cart Bar */}

      {totalCount > 0 ? (
        <div className="cart_bottom_bar" onClick={(localStorage.getItem("User") ? handleviewcart : () => { dispatch(setState({ key: "loginPage", value: true })); dispatch(setState({ key: "iframeLoader", value: true })); })}>
          <p className="view_cart_item">
            {totalCount && totalCount ? totalCount : 0} Items &#124; {totalPrice && totalPrice ? currency(totalPrice) : "₹ 0"}
          </p>
          <p className="view_cart_text">View Cart</p>
        </div>
      ) : (
        ""
      )}

      {/* Order summary band */}
      {orderPlaced && (
        <div onClick={() => history.push(localStorage.getItem("fullurl") ? `/${localStorage.getItem("fullurl")}/order-summary` : '/order-summary')} className="order_summary_band pointer">

          <div style={{ display: 'flex', alignItems: 'center' }}>

            <img src={order_placed_green} style={{ width: '40px' }} alt="NA" />
            <div style={{ marginLeft: '10px', marginTop: '5px' }}>
              <p style={{ marginBottom: 0 }} className="order_summary_band_text">Order with {businessName}</p>
              <p className="track_status_text bahnschrift" style={{ marginTop: 0 }}>Click to track status</p>
            </div>

          </div>

          <FontAwesomeIcon className='start-element' icon={faChevronRight} style={{ color: '#0375b1', height: '20px', marginLeft: '20px', marginRight: '10px' }} />

        </div>
      )}

      {/* App download Banner */}
      {/* {showDownloadBanner && (
        <div className="app_download_banner">
          <CloseIcon
            className="download-banner-close"
            style={{ pointerEvents: 'auto', marginTop: '5px' }}
            onClick={() => setShowDownloadBanner(false)}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img src={chattybao} style={{ width: '35px', marginRight: '10px' }} alt="logo" />
            <div onClick={() => window.open("https://chattybaouser.page.link/76UZ")}>
              <p className="download_text" style={{ margin: 0 }} >Download the App</p>
              <p className="download_sub_text" style={{ margin: 0 }}>For better experience & offers</p>
            </div>
          </div>
          <button onClick={() => window.open("https://chattybaouser.page.link/76UZ")} className="download_btn">Download</button>
        </div>
      )} */}



      {/* Variants pop up */}
      <Dialog classes={{ paper: classes.botm_dialog }} open={variantsVisible} PaperProps={{ sx: { width: "100%", marginTop: "auto", marginBottom: "0", marginLeft: '0', marginRight: "0" } }} onClose={() => setVariantsVisible(!variantsVisible)}>
        <div className="dialog_instruction_options">
          Options
          <img
            src={close_btn}
            className="close_btn2"
            onClick={() => {
              setVariantsVisible(!variantsVisible);
            }}
            alt="close"
          />
        </div>
        <DialogContent style={{ paddingLeft: "0px", paddingRight: "0px", paddingTop: 0 }}>
          <div>

            {variants.map((datad) => {
              let offer = '';
              console.log("discount value", data.discounted_rate);
              if (datad.variantDisPrice !== null && datad.variantDisPrice !== "" && datad.variantDisPrice !== undefined) {
                offer = 100 - (datad.variantDisPrice / datad.variantPrice) * 100;
                offer = Math.round(offer)
              }
              return (
                <>
                  <Card className="variantCard" key={datad.varientId}>
                    <div className="variant_container">
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '60%', paddingBottom: '10px', paddingLeft: '10px' }}>
                        <div>
                          {variantFoodType !== 'none' ? (
                            <img src={(variantFoodType === 'Veg' ? veg_icon : variantFoodType === 'Non-Veg' ? non_veg_icon : egg_icon)} style={{ height: '19px', paddingRight: '10px' }} alt="NA" />
                          ) : ''}
                          {variantIsBestseller ? (
                            <img src={variant_bestseller} style={{ height: '23px' }} alt="tag" />
                          ) : ''}

                        </div>
                        {(offer !== '' && offer != 0) ? (
                          <div style={{ position: 'relative' }}>
                            <p style={{ color: 'white', left: '7%' }} className="variants-offer">{offer}% OFF</p>
                          </div>
                        ) : ''}

                      </div>
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <CardContent className="main_image_container_variant" style={{ flex: 1 }}>
                          <Typography className="variant-name" color="text.secondary" style={{ lineHeight: "3", display: 'inline' }}>
                            {datad.variantName}
                          </Typography>
                        </CardContent>
                        {(offer !== '' && offer != 0) ? (
                          <div style={{ flex: 1, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>


                            <Typography
                              variant="subtile1"
                              className={`${offer !== "" ? 'normal_item' : 'price_without_discount'}`}
                              style={{ textAlign: "left", color: "black", fontWeight: "600", paddingTop: "4px", marginLeft: '25px', lineHeight: 'normal', wordBreak: 'break-all' }}
                            >
                              {datad.variantPrice && datad.variantPrice ? currency(datad.variantPrice * 1) : "₹ 5"}
                            </Typography>

                            <div style={{ display: 'flex', marginLeft: '25px' }}>
                              <Typography
                                variant="subtile1"
                                color="text.secondary"
                                className="product_offer_price"
                                style={{ textAlign: "left", color: "#000000", fontSize: "10px", paddingTop: "4px", paddingRight: '3px', display: `${offer !== "" ? 'inline' : 'none'}`, wordBreak: 'break-all' }}
                              >
                                {datad.variantDisPrice && datad.variantDisPrice ? currency(datad.variantDisPrice * 1) : " "}
                              </Typography>
                            </div>

                          </div>
                        ) : (
                          <div style={{ flex: 1, textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
                            <div style={{ display: 'flex', marginLeft: '25px' }}>
                              <Typography
                                variant="subtile1"
                                color="text.secondary"
                                className="product_offer_price"
                                style={{ textAlign: "left", color: "#000000", fontSize: "10px", paddingTop: "4px", paddingRight: '3px', wordBreak: 'break-all' }}
                              >
                                {datad.variantPrice && datad.variantPrice ? currency(datad.variantPrice * 1) : "₹ 5"}
                              </Typography>
                            </div>

                          </div>
                        )}

                        <CardContent className="pdLayover_addContainer" style={{ paddingLeft: "9%" }}>
                          {datad.count > 0 ? (
                            <span style={{ position: 'relative', display: 'flex', justifyContent: 'center' }}>
                              <div className="grid-add-box-filled-options">
                                <p className="plus_minus_text_non_grid pointer" onClick={() => decreaseCountForVariant(datad.count, datad.varientId, datad.id, null)}>-</p>
                                <p className="product_count2">{datad.count}</p>
                                <p className="plus_minus_text_non_grid pointer" onClick={() => addCountForVariant(datad.count, datad.varientId, datad.id, null)}>+</p>
                              </div>
                            </span>
                          ) : (
                            <div onClick={() => addCountForVariant(datad.count, datad.varientId, datad.id, null)} className="add-options-btn-non-grid">
                              <p className="add_text">ADD</p>
                            </div>


                          )}

                        </CardContent>
                      </div>

                    </div>


                  </Card>
                  {/* <Divider className="dividers" /> */}
                </>
              )
            }
            )}
          </div>


        </DialogContent>
        <DialogActions style={{ display: "flex", justifyContent: "center", padding: "0px 0px 15px 0px" }}>
          <img src={confirm_btn} style={{ height: "39px", width: "95%" }} onClick={() => setVariantsVisible(false)} alt='confirm' />
        </DialogActions>
      </Dialog>

      {/* Product details */}
      <Dialog
        open={productDetailVisible}
        className=""
        fullScreen
        classes={{
          paper: classes.bigscreens,
        }}
        PaperProps={{ sx: { width: "100%", marginTop: 'auto', marginBottom: 0, height: '80%', paddingRight: 0 } }}
      >
        <img
          src={close_btn}
          // className={classes.closeIcon}
          className="product-close-icon"
          onClick={() => {
            setProductDetailVisible(!productDetailVisible);
          }}
          alt='close'
        ></img>
        <div style={{ marginTop: '-25px' }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            <div style={{ position: 'relative' }}>
              {productImage.length > 1 ? (
                <Slider {...settings}>
                  {productImage.map((data, index) => (
                    <TransformWrapper panning={{ disabled: true }}>
                      <TransformComponent>
                        <div
                          style={{
                            display: "flex",
                            textAlign: "center",
                            color: "blue",
                            fontWeight: "bold",
                            flexDirection: "column",
                            border: "0px",
                            alignItems: "center",
                            borderRadius: "0px",
                            width: "100%",
                            margin: "0px auto",
                          }}
                        >
                          <img
                            src={data}
                            alt="img"
                            style={{
                              width: "100% !imprtant",
                              objectFit: "cover",
                              borderRadius: "0px",
                            }}
                            height={363}
                            onClick={(e) => {
                              alert("0");
                              if (this.state.isMoving) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                      </TransformComponent>
                    </TransformWrapper>
                  ))}
                </Slider>
              ) : (
                <>
                  {productImage[0] ? (
                    <img src={productImage[0]} style={{ width: '100%' }} alt="img" />
                  ) : (
                    <img src={imageNotAvailable} className="no-image-product" style={{ width: '100%' }} alt="img" />
                  )}
                </>
              )}
              {console.log("pdImage==>", productImage)}

              <div style={{ display: `${pdOffer !== '' ? 'in-line' : 'none'}` }}>
                <img src={discount_tag} style={{ width: '75px', display: 'block', position: 'absolute', top: 0, bottom: 0, right: 0, left: 0 }} />
                <p className='ofr_text'>{pdOffer}%<br />OFF</p>
              </div>
              {ifBestSeller ? (
                <img src={bestSeller_tag} style={{ width: '140px', position: 'absolute', top: '3%', right: 0, left: 'inherit', display: 'block' }} />
              ) : ''}

              <div className='button_container'>
                <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={() => dispatch(setState({ key: "whatsappOpen", value: true }))}>
                  <img src={homepageWhatsappBlack} style={{ height: '18px', paddingRight: '10px' }} alt="chat" />
                  <p className='button'>Chat</p>
                </span>
                <span className='hor_divider'></span>
                <span style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handleShare}>
                  <img src={share_icon} style={{ height: '18px', paddingRight: '10px' }} alt="share" />
                  <p className='button'>Share</p>
                </span>
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <div className='pd_details'>
                <p className="pd_brandName">{pdBrand}</p>
                <p style={{ wordBreak: 'break-word' }} className='pd_name'>{productName}</p>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '20px', justifyContent: 'space-between' }}>
                  <div className="product-page-price-container">
                    <p className={pdOffer !== '' ? 'old_price_product' : 'discount_price'}>{currency(productPrice * 1)}</p>
                    <p style={{ display: `${pdOffer !== '' ? 'in-line' : 'none'}` }} className='discount_price'>{currency(productDiscPrice * 1)}</p>
                  </div>
                  {productCount > 0 ? (
                    <span
                      style={{ paddingBottom: "2px", position: "relative" }}
                    >

                      <div className="grid-add-box-filled-options add-minus-btn-product">
                        <p className="plus_minus_text_non_grid pointer" onClick={productVariantExists ? () => handleVariantsButton(varDataFromPd) : () => decreaseCount(productCount, productId)}>-</p>
                        <p className="product_count2">{productCount}</p>
                        <p className="plus_minus_text_non_grid pointer" onClick={productVariantExists ? () => handleVariantsButton(varDataFromPd) : () => addCount(productCount, productId)}>+</p>

                      </div>
                    </span>
                  ) : (productAvailabilityData !== '' ? (
                    <span
                      className="maincard_button"
                      style={{
                        marginRight: '10px'
                      }}
                    >
                      <div style={{ width: '100px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #D9D9D9' }}>
                        <p className="product_availability_text">{productAvailabilityData}</p>
                      </div>
                    </span>
                  ) : (
                    <span
                      className="maincard_button pointer"
                      style={{
                        marginRight: '10px'
                      }}
                      onClick={orderPlaced ? giveToastMessage : (productVariantExists ? () => handleVariantsButton(varDataFromPd) : () => addCount(productCount, productId, "fromHome"))}
                    >
                      <div style={{ width: '85px', backgroundColor: 'white', borderRadius: '5px', border: '1px solid #125d8d', height: '28px' }}>
                        {productVariantExists ? (
                          <p className="options_text">{`${pdVariantLength} Options`}</p>
                        ) : (
                          <p className="add_text">ADD</p>
                        )}

                      </div>
                    </span>
                  ))}


                </div>
                <div style={{ display: `${productDesc === '' || productDesc === null || productDesc === undefined ? 'none' : 'block'}` }}>
                  <Divider />
                  <p className='desc_heading'>Product Description</p>
                  <p className='desc_text' style={{ wordBreak: 'break-word' }}>{productDesc}</p>
                </div>

                <Divider />
                <p className='desc_heading'>Terms & Conditions</p>
                <p className='desc_text'>All images are for representation purpose only. While every effort has been made to maintain accurate and up to date product related content, it is recommended to read product labels, batch and manufacturing details along with warnings, and directions before using or consuming a packaged product. For other products or any additional information, please contact the seller and incase you wish to contact the manufacturer, the address mentioned on the product label should be used.</p>
                <Divider />
              </div>
            </div>
          </div>
        </div>
      </Dialog>

      {/* order summary pop up */}
      <Dialog
        open={showOrderSummary}
        classes={{
          paper: classes.bigscreens,
        }}
        fullScreen
        PaperProps={{ sx: { width: "100%", marginTop: 'auto', marginBottom: 0, height: '80%', backgroundColor: '#e8e8e8', paddingBottom: '100px' } }}
      >
        <img
          src={close_icon}
          className={classes.closeIcon}
          onClick={() => {
            setShowOrderSummary(!showOrderSummary);
          }}
          alt='close'
        />
        <div style={{ paddingLeft: '10px', paddingRight: '10px', position: 'relative' }}>

          <div className="orderdetails_header" style={{ width: "100%", backgroundColor: '#e8e8e8' }}>
            <p className="order_details_text bahnschrift" style={{ textAlign: 'center' }}>Order Summary</p>
          </div>

          <div style={{ marginBottom: '10px', marginTop: '50px' }} className="order_summary_containers">
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <p className="order_id_date_text" style={{ margin: 0 }}>Order ID: {orderStatus.orderId}</p>
              <p className="order_id_date_text" style={{ margin: 0 }}>{orderStatus.orderDate}</p>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', marginTop: '10px' }}>
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={order_placed_green} style={{ width: '40px' }} alt="NA" />
                <p className="summary_icon_texts" style={{ textAlign: 'center', marginTop: '5px', marginBottom: 0 }}>Order Placed</p>
              </div>
              <div className="summary_horiz_divider" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={orderStatus.stageStatus >= 2 ? payment_green : payment_grey} style={{ width: '40px' }} alt="NA" />
                <p className="summary_icon_texts" style={{ textAlign: 'center', marginTop: '5px', marginBottom: 0 }}>Payment</p>
              </div>
              <div className="summary_horiz_divider" />
              <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <img src={(orderStatus.deliveryMode === 0 && orderStatus.shipmentStatus === 5) ? order_delivered_green : order_delivered_grey} style={{ width: '40px' }} alt="NA" />
                <p className="summary_icon_texts" style={{ textAlign: 'center', marginTop: '5px', marginBottom: 0 }}>Delivery</p>
              </div>
            </div>
          </div>

          <div className="order_summary_containers">
            <p className="order_status_title bahnschrift">{logisticData.orderStatusTitle}</p>
            <p className="order_status_text bahnschrift">{logisticData.orderStatusText}</p>
          </div>

          {Object.keys(cartDat).length !== 0 && cartDat.map(item => (
            <div style={{ marginTop: '10px' }} className="order_summary_containers">
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                <div style={{ display: 'flex', flex: 2 }}>
                  <img src={item.image_url[0]} style={{ width: '40px', height: '40px' }} alt="NA" />
                  <p className="summary_pd_text line_clamp2" style={{ alignSelf: 'center', margin: 0, marginLeft: '5px' }}>{item.item_name}</p>
                </div>

                <p className="summary_pd_num" style={{ flex: 1, textAlign: 'center' }}>x {item.count}</p>

                <div style={{ display: 'flex', flex: 2, justifyContent: 'center', alignItems: 'flex-end' }}>
                  <p className={item.discounted_rate ? 'summary_cutout_price' : 'summary_pd_num'}>{currency(item.rate)}</p>
                  {item.discounted_rate && (
                    <p className="summary_pd_num">{currency(item.discounted_rate)}</p>
                  )}
                </div>

              </div>
              {(item.instructions !== '' && item.instructions !== null && item.instructions !== 'null' && item.instructions !== undefined) && (
                <p className="order_instruction">{item.instructions}</p>
              )}
            </div>
          ))}

          {(logisticData.customProduct !== '' && logisticData.customProduct !== null && logisticData.customProduct !== 'null' && logisticData.customProduct !== undefined && logisticData.customProduct.length !== 0) && (
            <div className="custom_pd_container">
              {logisticData.customProduct.map((value, index) => (
                <div className="custom_pd">
                  <div className="green_bullet" />
                  <p className="summary_custom_item_text">{value}</p>
                </div>
              ))}
            </div>
          )}

          <div style={{ marginBottom: '10px', backgroundColor: 'white', marginLeft: 0, marginRight: 0, marginTop: '10px' }} className='bill_container'>
            <p className='bill_subtext bill_large_font_size' style={{ color: 'black', marginBottom: '5px' }}>Bill Details</p>
            <div>
              {logisticData.savings !== 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <p className='bill_subtext'>MRP Total</p>
                  <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.mrpTotal)}</p>
                </div>
              )}

              {logisticData.savings !== 0 && (
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                  <p className='bill_subtext'>Savings</p>
                  <p style={{ color: '#2bb673' }} className='bill_amount'>- {currency(logisticData.savings)}</p>
                </div>
              )}
              <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                <p className='bill_subtext'>Item Total</p>
                <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.itemTotal)}</p>
              </div>
              <div className='cart_divider' />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className='bill_subtext'>Delivery Charges</p>
                <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.deliveryCharge)}</p>
              </div>
              {(logisticData.govtTax !== 0 && logisticData.govtTax !== "undefined" && logisticData.govtTax !== "null") && (
                <div style={{ display: 'flex', justifyContent: 'space-between', paddingTop: '5px' }}>
                  <p className='bill_subtext'>Govt Taxes</p>
                  <p style={{ color: 'black' }} className='bill_amount'>{currency(logisticData.govtTax)}</p>
                </div>
              )}
              {(logisticData.cashback !== 0 && logisticData.cashback !== "undefined" && logisticData.cashback !== "null") && (
                <>
                  <div className='cart_divider' />
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="bill_amount_bold" style={{ color: 'black', fontWeight: '300', marginBottom: 0, marginTop: 0 }}>{logisticData.offerText}</p>
                    <p className='bill_amount_bold' style={{ color: '#2bb673' }}>{'- ' + currency(logisticData.cashback)}</p>
                  </div>
                  {/* <p className="cashback_subtext">Will be credited within 24 hours</p> */}
                </>
              )}
              <div className='cart_divider' />
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <p className='bill_subtext bill_large_font_size' style={{ color: 'black' }}>Amount To Pay</p>
                <p className='bill_amount_bold' style={{ color: 'black' }}>{currency(logisticData.payAmount)}</p>
              </div>


            </div>

          </div>

          <div className="summary_total_container">
            <p className="summary_total_text">Total: {currency(logisticData.payAmount)}</p>
            <p className="item_saving_text">{cartDat.length} Items {logisticData.savings !== 0 && (<span className="item_saving_text">| {currency(logisticData.savings)} savings</span>)}</p>
          </div>
          {console.log("LogisticData", logisticData)}
        </div>
      </Dialog>

      {/* Login Page iframe */}
      <Dialog
        open={loginPage}
        fullScreen
        classes={{
          paper: classes.iframe,
        }}
        PaperProps={{ sx: aboutUsPopupStyle }}
        onClose={() => dispatch(setState({ key: "loginPage", value: !loginPage }))}
      >
        <img
          src={close_btn}
          className="login-close-btn-iframe"
          onClick={() => {
            dispatch(setState({ key: "loginPage", value: !loginPage }))
          }}
          alt="close"
        />
        {/* <DialogContent> */}
        <div className="iframe-container">
          {iframeLoader && <ClipLoader className="iframe-loader" color="#125d8d" />}
          <iframe className="iframe-style" title="Login" id="iframe-id" src={iframeUrl} onLoad={handleLoad} />
        </div>
        {/* </DialogContent> */}
      </Dialog>
      {console.log("ModifiedData", modificationData)}
      {htmlContent && (
        <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
      )}
    </div>
  );
};

export default function Index(props) {
  const { Images, searchInput, cartVisible, setCartVisible, menuOption, setMenuOption } = props;
  const [isFruitsAndVegMerchant, setIsFruitsAndVegMerchant] = useState("");
  const merchantdetails = { data: JSON.parse(localStorage.getItem("merchantdetails")).merchantDetails }
  console.log("MerInsude", merchantdetails)
  let l3 = merchantdetails && merchantdetails.data.categoryl3;
  let PhoneNumber = merchantdetails && merchantdetails.data.mainmobilenumber;
  let merchId = merchantdetails && merchantdetails.data.merchantid;

  useEffect(() => {
    if ("95f732e0-737b-11ec-9806-93163a488dc9" == l3 || "3d795333-84ae-11ec-a0d0-795c4aba562b" == l3) {
      setIsFruitsAndVegMerchant("FRUITS");
    } else if ("8650b2d0-737b-11ec-9806-93163a488dc9" == l3) {
      setIsFruitsAndVegMerchant("GROCERY");
    } else {
      setIsFruitsAndVegMerchant("");
    }
  }, []);

  return (
    <>
      {/* <Cart /> */}
      <Page
        Images={Images}
        searchInput={searchInput}
        isFruitsAndVegMerchant={isFruitsAndVegMerchant}
        PhoneNumber={PhoneNumber}
        gridType={merchantdetails.data.gridtype}
        businessName={merchantdetails.data.shopname}
        merchId={merchId}
        isCheckout={merchantdetails.data.eligibility_of_checkout}
        shopOff={merchantdetails.data.shopClosed}
        cartVisible={cartVisible}
        setCartVisible={setCartVisible}
        catalogCount={merchantdetails.data.catalogue_count}
        userOfferBanner={merchantdetails.data.userOfferBanner}
        menuOption={menuOption}
        setMenuOption={setMenuOption}
        picUrl={merchantdetails.data.picurl}
      />
    </>
  );
}
